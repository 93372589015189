var fechaActual = new Date();
var PARAM_NUMERO_CONCEPTOS = "5";
var PARAM_FECHA_ACTUAL = fechaActual.getDate() + "/" + (fechaActual.getMonth() + 1) + "/" + fechaActual.getFullYear();
var PARAM_TARJETA_TRIBUTARIA = "";

function onInit(){
  reiniciar();
}

function send() {
  var datos = appEnviaDatos();
  var datosimp = appEnviaImpresion();
  var cadenaoriginal = appEnviaCadOriginal();
  var datosxml = getXMLValue();

  document.getElementById("Datos").value = datos;
  document.getElementById("DatosImp").value = datosimp;
  document.getElementById("DatosImpresion").value = datosimp;
  document.getElementById("CadOriginal").value = cadenaoriginal;
  document.getElementById("DatosXML").value = datosxml;

  if (datos == "MSG|") {
    alert("Debes cerrar la ventana de Dialogo.");
    return false;
  }

  if (datos == "EOF|") {
    alert("Debes capturar al menos un concepto.");
    return false;
  }

  if (datosxml == "*") {
    alert("Debe confirmar previamente sus datos.");
    return false;
  }

  return true;
}
