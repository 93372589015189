//Constantes
//Elaborado por David Aguilera Cuevas jul2016

var PERSONA_FISICA = 'PF';
var PERSONA_MORAL = 'PM';
// � � � � � � � � � � � � < > ( ) [ ] { } & # / @ _ | � ! � ? � $ ' '
//** * Caracteres especiales unicode. <BR/>(&#xc1; &#xc9; &#xcd; &#xd3; &#xda; &#xe1; &#xe9; &#xed; &#xf3; &#xfa; &#xd1; &#xf1; &#x3c; &#x3e; &#x28; &#x29; &#x5b; &#x5d; &#x7b; &#x7d; &#x26; &#x23; &#x2f; &#x40; &#x5f; &#x7c; &#xa1; &#x21; &#xbf; &#x3f; &#xb0; &#x24; &#x22; &#x27;). */
var UNICODE = ['\u00c1','\u00c9','\u00cd','\u00d3','\u00da','\u00e1','\u00e9','\u00ed','\u00f3','\u00fa','\u00d1','\u00f1','\u003c','\u003e','\u0028','\u0029','\u005b','\u005d','\u007b','\u007d','\u0026','\u0023','\u002f','\u0040','\u005f','\u007c','\u00a1','\u0021','\u00bf','\u003f','\u00b0','\u0024','\u0022','\''];

// � � � � � � � � � � � � < > ( ) [ ] { } & # / @ _ | � ! � ? � $ ' '
//** * Caracteres especiales unicode para XML. <BR/>'&#x26;&#x23;xc1;', '&#x26;&#x23;xc9;', '&#x26;&#x23;xcd;', '&#x26;&#x23;xd3;', '&#x26;&#x23;xda;', '&#x26;&#x23;xe1;', '&#x26;&#x23;xe9;', '&#x26;&#x23;xed;', '&#x26;&#x23;xf3;', '&#x26;&#x23;xfa;', '&#x26;&#x23;xd1;', '&#x26;&#x23;xf1;', '&#x26;&#x23;x3c;', '&#x26;&#x23;x3e;', '&#x26;&#x23;x28;', '&#x26;&#x23;x29;', '&#x26;&#x23;x5b;', '&#x26;&#x23;x5d;', '&#x26;&#x23;x7b;', '&#x26;&#x23;x7d;', '&#x26;&#x23;x26;', '&#x26;&#x23;x23;', '&#x26;&#x23;x2f;', '&#x26;&#x23;x40;', '&#x26;&#x23;x5f;', '&#x26;&#x23;x7c;', '&#x26;&#x23;xa1;', '&#x26;&#x23;x21;', '&#x26;&#x23;xbf;', '&#x26;&#x23;x3f;', '&#x26;&#x23;xb0;', '&#x26;&#x23;x24;', '&#x26;&#x23;x22;' y '&#x26;&#x23;x27;'. */
var XML_UNICODE = ['&#xc1;','&#xc9;','&#xcd;','&#xd3;','&#xda;','&#xe1;','&#xe9;','&#xed;','&#xf3;','&#xfa;','&#xd1;','&#xf1;','&#x3c;','&#x3e;','&#x28;','&#x29;','&#x5b;','&#x5d;','&#x7b;','&#x7d;','&#x26;','&#x23;','&#x2f;','&#x40;','&#x5f;','&#x7c;','&#xa1;','&#x21;','&#xbf;','&#x3f;','&#xb0;','&#x24;','&#x22;','&#x27;'];
//Fin constantes
var MENSUAL = 0;
var BIMESTRAL = 1;
var TRIMESTRAL = 2;
var CUATRIMESTRAL = 3;
var SEMESTRAL = 4;
var DEL_EJERCICIO = 5;
var BACKSPACE = 8;
var TAB = 9;
var IZQ = 37;
var DER = 39;
var SUPR = 46;
var ENTER = 13;
var INICIO = 36;

var MODO_TARJETA_TRIBUTARIA = 4;
// 						   0                                        1                      2                3             4                5                    6           7                     8            9                             10                          11                          12                 13                                                    14                                    15                                            16                           17                       18                      19                        20                                            21                                22                   23                                 24                                  25                   26                  27                        28                          29                   30              31                        32                              33                           34
var descripConcepto = 	['Nombre del Concepto',                     'Clave del Concepto',  'Per\u00edodo',  'Ejercicio',  'Tipo de Pago',  'Impuesto a Favor',  'Importe',  'Parte Actualizada',  'Recargos',  'Multa por Correcci\u00f3n',  'Total de Contribuciones',  'Cr\u00e9dito al Salario',  'Compensaciones',  'Cr\u00e9dito IEPS Diesel sector primario y minero',  'Diesel automotriz para transporte',  'Uso de infraestructura carretera de cuota',  'Producci\u00f3n de agave',  'Otros Est\u00edmulos',  'Certificados TESOFE',  'Total de aplicaciones',  'Fecha del pago realizado con anterioridad',  'Monto pagado con anterioridad',  'Cantidad a Cargo',  'Importe de la 1ra. Parcialidad',  'Importe sin la 1ra. Parcialidad',  'Saldo a Favor',  'Cantidad Pagada',  'Tipo de Contribuyente',  'N\u00famero de Concepto',  'Tipo de Impuesto',  'Exclusiones',  'Tipos de per\u00edodo',  'Clave de Referencia del DPA',  'Cadena de la dependencia',  'No. Transacci\u00f3n DPA'];
var claveInfIVA = 		['IVA actos accidentales',                  '12001',               '12002',         '12027',      null,            null,                '12004',    '12005',              '12006',     '12007',                      '12008',                    null,                       null,              null,                                                 null,                                 null,                                         null,                        null,                    null,                   null,                     null,                                         null,                             null,                null,                              null,                               null,                '12020',            'AP',                     '23',                       '300',               null,           '10000000',               null,                           null,                        '12038'];
var claveInfDPA = 		['Derechos, Productos y Aprovechamientos',  '14701',               '14702',         '14727',      null,            null,                '14704',    '14705',              '14706',     '14707',                      '14708',                    null,                       '14714',           null,                                                 null,                                 null,                                         null,                        null,                    null,                   null,                     null,                                         null,                             null,                null,                              null,                               '14703',             '14720',            'AP',                     '47',                       '900',               null,           '11111001',               '14733',                        '14734',                     '14738'];

var ColorDisabled = 0xDDDDDD;
var ColorEnabled = 0xFFFFFF;
//Globales
//Elaborado por David Aguilera Cuevas jul2016

var error = false;
var debug = true;
var pagos = new Array();
var dpa;

//Funciones globales

function sinClipboard() {
	var tecla = String.fromCharCode(event.keyCode).toLowerCase();
	
	if (event.ctrlKey && !event.altKey) {
	event.returnValue = false;
	}
	if(event.keyCode==13){
	   var elem = document.activeElement;
	   var elems = document.getElementsByTagName('input');
       var tidx  = +(elem.getAttribute('tabindex'));
       for(var ind = tidx + 1; ind < elems.length;ind++){
    	   var sigElem = siguienteTab(ind);
    	   if(sigElem !==null){
	    	   if(sigElem.disabled==false){
	    		   sigElem.focus();
	    		   break;
	    	   }
    	   }
       }
       event.returnValue = false;
	}
}

function siguienteTab(ind){
   var elems = document.getElementsByTagName('input');
   var res = null;
   for (var i=0; i<elems.length; i++) 
   {
       var tidx2 = elems[i].getAttribute('tabindex');
       if (tidx2 == ind){
    	   	res = elems[i];
        	break;
       }
   }
   return res;
}


function msgDebug(mensaje){
	if(debug==true)
		alert(mensaje);
}

function hereda(proto){
	function F(){}
	F.prototype = proto;
	return new F;
}

if (!Array.prototype.indexOf) {
    Array.prototype.indexOf = function (searchElement /*, fromIndex */ ) {
        "use strict";
        if (this == null) {
            throw new TypeError();
        }
        var t = Object(this);
        var len = t.length >>> 0;
        if (len === 0) {
            return -1;
        }
        var n = 0;
        if (arguments.length > 1) {
            n = Number(arguments[1]);
            if (n != n) { // para verificar si es NaN
                n = 0;
            } else if (n != 0 && n != Infinity && n != -Infinity) {
                n = (n > 0 || -1) * Math.floor(Math.abs(n));
            }
        }
        if (n >= len) {
            return -1;
        }
        var k = n >= 0 ? n : Math.max(len - Math.abs(n), 0);
        for (; k < len; k++) {
            if (k in t && t[k] === searchElement) {
                return k;
            }
        }
        return -1;
    };
}

if (!String.prototype.trim) {
	  String.prototype.trim = function () {
	    return this.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
	  };
}
//Fin Globales
//Clase ElementoPago
//Elaborado por David Aguilera Cuevas jul2016
//

var ElementoPago = function(valor, mascara, campoDestino){
	this.valor = valor;
	this.valorXML = '';
	this.valorImpresion = '';
	this.claveInformatica = '0';
	this.logitudMin = 0;
	this.logitudMax = 0;
	this.valorConMascara = '';
	this.mascara = mascara;
	this.campoDestino = campoDestino;
	this.ponerValor(valor);
	this.errorValidacion = false;
	this.obligatorio = false;
	
};
ElementoPago.prototype = {
		
	valor : '',
	valorXML:'',
	valorImpresion:'',
	claveInformatica:'0',
	logitudMin:0,
	logitudMax:0,
	valorConMascara:'',
	mascara:'',
	campoDestino:'',
	errorValidacion:false,
	obligatorio:false,
		
	ponerValor : function(valor){
		this.valor = ''+ valor;		//Lo obligamos a ser un string
		this.valorImpresion = this.valorParaImpresion();
		this.valorConMascara = this.enmascarar();
		this.valorXML = this.representarEnXML();
	},
	enmascarar : function(){
		var res = this.valor;

		return res;
	},
	representarEnXML : function(){
		
		var res = "<CONCEPTO>" +
		"<CLAVE>" + this.convertirUNICODE(this.claveInformatica) + "</CLAVE>" +
		"<DESCRIPCION>" + this.convertirUNICODE(this.descripcion) + "</DESCRIPCION>" +
		"<VALOR>" + this.convertirUNICODE(this.valor) + "</VALOR>" + 
		"<VALORIMPRESION>" + this.convertirUNICODE(this.valorImpresion) + "</VALORIMPRESION>" +
		"</CONCEPTO>";
		
		return res;
	},
	convertirUNICODE : function(valor){
		var res;
		var ind;
		var car;
		var posicion;
		
		res = "";
		if(typeof valor !== 'undefined'){
			for(ind=0;ind < valor.length;ind++){
				car = valor.charAt(ind);
				if((posicion=UNICODE.indexOf(car))>=0){
					res = res + XML_UNICODE[posicion];
				}
				else
					res = res + car;
			}
		}
		return res;
	},
	ponerEnDestino : function(){
		var destino = document.getElementById(this.campoDestino);
		if(destino !== null)
			destino.value = this.valorConMascara;
	},
	ponerEnDestinoSinMascara : function(){
		var destino = document.getElementById(this.campoDestino);
		if(destino !== null)
			destino.value = this.valor;
	},
	recuperarDeDestino : function(){
		var destino = document.getElementById(this.campoDestino);
		if(destino !== null){
			this.valor = destino.value.toUpperCase();
			this.ponerValor(this.valor);
		}
	},
	validar : function(){
		;
	},
	esFechaValida : function(fecha){
		//Valida que la fecha est� en formato AAMMDD
		var res = false;
		var ind;
		
		if(fecha.length==6){
			for(ind=0;ind<fecha.length;ind++){
				if(!this.esNumerico(fecha.charAt(ind)))
					return res;
			}
			
			var anio = fecha.substring(0,2);
			var mes  = fecha.substring(2,4);
			var dia  = fecha.substring(4,6);

			if(parseInt(mes,10) < 1 || parseInt(mes,10)>12)
				return res;
			
			var ultimoDia = new Date(parseInt(anio, 10) + 1900, parseInt(mes, 10), 0).getDate();
			if(parseInt(dia, 10) < 1 || parseInt(dia, 10) > ultimoDia)
				return res;

			res = true;
		}
		
		return res;
	},
	representarParaImpresion : function(){
		;
	},
	esAlfabetico : function(valor){
		
		var res = (valor>='A' && valor<='Z');
		return res;
	},
	esNumerico : function(valor){

		var res = (valor>='0' && valor<='9');
		return res;
	},
	esEnie : function(valor){
		
		var res = (valor=='\u00d1');
		return res;
	},
	esAmp : function(valor){
		
		var res = (valor=='\u0026');
		return res;
	},
	esApostrofe : function(valor){
		
		var res = (valor=='\u0027');
		return res;
	},
	esEspacio : function(valor){
		
		var res = (valor==' ');
		return res;
	},
	esPunto : function(valor){
		
		var res = (valor=='\u002e');
		return res;
	},
	esEspecialRazonSocial : function(valor){
		//@%#�!$"-/+.,
		
		var res = (valor=='\u0040' || valor=='\u0025' || valor=='\u0023' || valor=='\u00a1' ||
				valor=='\u0021' || valor=='\u0024' || valor=='\u0022' || valor=='\u002d' ||
				valor=='\u002f' || valor=='\u002b' || valor=='\u002e' || valor=='\u002c');
		return res;
	},

	esSignoMenos : function (valor){
		var res = (valor=='-');
		return res;
	},

	habilitar : function(valor){
		var destino = document.getElementById(this.campoDestino);
		if(typeof destino  !== 'undefined'){
			if(valor==true){
				destino.disabled = false;
				destino.style.backgroundColor=ColorEnabled;
			}
			else{
				destino.disabled = true;
				destino.style.backgroundColor=ColorDisabled;				
			}
		}
	},
	mostrar : function(valor){
		var destino = document.getElementById(this.campoDestino);
		if(typeof destino  !== 'undefined'){
			if(valor==true)
				destino.style.display = 'block';
			else
				destino.style.display='none';
		}
	},
	ponerCampo : function(valor){
		this.ponerValor(valor);
		this.ponerEnDestino();
	},
	ponerFoco : function(){
		document.getElementById(this.campoDestino).focus();
	},
	onKeyDown : function(event){
		var res = false;

		if (event.ctrlKey && !event.altKey) {
			event.returnValue = false;
			return res;
		}		
		
		var tecla = ('which' in event) ? event.which : event.keyCode;

		switch(tecla){
		case BACKSPACE:
		case TAB:
		case IZQ:
		case DER:
		case SUPR:
		case INICIO:
			res = true;
			break;
		case ENTER:
			   var elem = document.activeElement;
			   var elems = document.getElementsByTagName('input');
		       var tidx  = +(elem.getAttribute('tabindex'));
		       for(var ind = tidx + 1; ind < elems.length;ind++){
		    	   var sigElem = siguienteTab(ind);
		    	   if(sigElem !==null){
			    	   if(sigElem.disabled==false){
			    		   sigElem.focus();
			    		   break;
			    	   }
		    	   }
		       }
		       event.returnValue = false;
		       res = false;
			break;
		default:
			res = true;
			break;
		}
		
		event.returnValue = res;
		return res;
	},

	onKeyyPress : function(event){
		var res = false;

		var tecla = ('which' in event) ? event.which : event.keyCode;

		switch(tecla){
		case 0:
		case BACKSPACE:
		case TAB:
		case SUPR:
			res = true;
			break;
		default:
			res = false;
			break;
		}
		
		//event.returnValue = res;
		return res;
	},

	onChange : function(event){
		var res = true;
		
		event.returnValue = res;
		return res;
	},

	habilitado : function(){
		var res = document.getElementById(this.campoDestino).disabled;
		res = !res;
		return res;
	},

	sinCaracter : function(caracter){
		var res = "";
		var separado = this.valor.split(caracter);
		for(var ind=0;ind <separado.length; ind++)
			res = res + separado[ind];
		
		return res;
	},

	valorParaImpresion : function(){
		var res = this.valor;
		
		return res;
	}
};

//Fin Clase ElementoPago
//Clase Numerico
//Elaborado por David Aguilera Cuevas jul2016
//

var Numerico = function(valor, mascara, campoDestino){
	ElementoPago.call(this, valor, mascara, campoDestino);
	this.descripcion = 'Importe';
	this.longitudMin=0;
	this.longitudMax=14;
	this.claveInformatica = '14704';
};
Numerico.prototype = hereda(ElementoPago.prototype);
Numerico.prototype.constructor = Numerico;
Numerico.prototype.ponerValor = function(valor){

	if(valor.length > 0){
		valor = valor.replace(/,/g,"");
		valor = "" + parseInt(valor, 10);
	}
	ElementoPago.prototype.ponerValor.call(this, valor);
	
};
Numerico.prototype.valorNumerico = function(){
	var res = 0;
	if(this.valor !=='')
		res = parseInt(this.valor, 10);
	
	return res;
};
Numerico.prototype.enmascarar = function(){
	var res = '';
	var indMascara = this.mascara.length - 1;
	var valor = "" + this.valor;
	
	for(var ind=valor.length - 1;ind>=0;ind--){
		if(this.mascara.length > 0 && indMascara>=0){
			switch(this.mascara.charAt(indMascara)){
			case '9':
				res = valor.charAt(ind) + res;
				break;
			case ',':
				if(this.esNumerico(valor.charAt(ind))){
					res = valor.charAt(ind) + ',' + res;
					indMascara--;
				}
				else
					res = valor.charAt(ind) + res;
				break;
			}
		}else
			res = valor.charAt(ind) + res;
		indMascara--;
	}
	
	return res;
};

Numerico.prototype.valorPesos = function(){

	var res = "$" + this.enmascarar() + ".00";
	
	return res;
};

Numerico.prototype.validar = function(){
	var error = false;
	var car;
	var ind;

	//Valida contenido, deben ser s�lo n�meros
	for(ind=0;ind<this.valor.length && !error;ind++){
		car = this.valor.charAt(ind);
		if(!this.esNumerico(car) && !this.esSignoMenos(car)){
			error=true;
			break;
		}
	}
	if(!error){
		if(this.valor.length > this.longitudMax){
			this.errorValidacion = true;
			throw this.descripcion + " no debe ser mayor a " + this.longitudMax + " d\u00edgitos."; 
		}
	}
	
	this.errorValidacion = error;
	return error;
};

Numerico.prototype.onKeyDown = function(event){
	var res = false;

	if((res=ElementoPago.prototype.onKeyDown(event))==false && !event.shiftKey){
		var tecla = ('which' in event) ? event.which : event.keyCode;
		//alert(event.shiftKey + " " + tecla);
		res = ((tecla >=0x30 && tecla <=0x39) || (tecla >=0x60 && tecla <=0x69)? true: false);
	}
	return res;
};

Numerico.prototype.valorParaImpresion = function(){
	
	var res = this.valorPesos();
	
	return res;
};
Numerico.prototype.onKeyyPress = function(event){
	var res = false;
	
	var tecla = ('which' in event) ? event.which : event.keyCode;
	var car = String.fromCharCode(tecla).toUpperCase();
	if((res=ElementoPago.prototype.onKeyyPress(event))==false)
		res = this.esNumerico(car);
	
	event.returnValue = res;
	return res;
};


//Fin Numerico//Clase Importe
//Elaborado por David Aguilera Cuevas jul2016
//

var Importe = function(valor, mascara, campoDestino){
	ElementoPago.call(this, valor, mascara, campoDestino);
	this.descripcion = 'Importe';
	this.longitudMin=0;
	this.longitudMax=14;
	this.claveInformatica = '14704';
};
Importe.prototype = hereda(Numerico.prototype);
Importe.prototype.constructor = Importe;
Importe.prototype.validar = function(){
	var error = false;

	//Valida la longitud
	if(!error){
		if(this.valor.length<=this.longitudMax)
			;
		else{
			error=true;
			alert(this.descripcion + "\nLongitud no v\u00e1lida.");
		}
	}
	
	if(!Numerico.prototype.validar()){
		error=true;
		alert(this.descripcion + "\nEl importe contiene caracteres no permitidos.");
	}
	
	this.errorValidacion = error;
	return error;
};
//Fin Importe//Clase RFC
//Elaborado por David Aguilera Cuevas jul2016
//

var RFC = function(valor, mascara, campoDestino){

	ElementoPago.call(this, valor, mascara, campoDestino);
	this.descripcion = 'RFC';
	this.longitudMin=12;
	this.longitudMax=13;
	this.claveInformatica = '10001';
	this.tarjetaTributaria = false;
	this.nombreTarjetaTrib = '';
	this.modo = 0;
	this.procesoBandaOCodigo = false;
};
RFC.prototype = hereda(ElementoPago.prototype);
RFC.prototype.constructor = RFC;
RFC.prototype.ponerValor = function(valor){
	
	this.valor = valor;
	switch(this.valor.length)
	{

		case 12:
			this.siglas = this.valor.substring(0, 3);
			this.fecha = this.valor.substring(3, 9);
			this.homo = this.valor.substring(9, 12);
			this.persona = PERSONA_MORAL;
			break;
		case 13:
			this.siglas = this.valor.substring(0, 4);
			this.fecha = this.valor.substring(4, 10);
			this.homo = this.valor.substring(10, 13);
			this.persona = PERSONA_FISICA;
			break;
		default:
			this.siglas = '';
			this.fecha = '';
			this.homo = '';
			this.persona = '';
			break;
	}
	ElementoPago.prototype.ponerValor.call(this, valor);
	
};
RFC.prototype.validar = function(){
	var error = false;
	var car;
	var ind;

	
	//Valida la longitud
	if(!error){
		if(this.valor.length==12 || this.valor.length==13)
			;
		else{
			error=true;
			alert(this.descripcion + "\nLongitud no v\u00e1lida.");
		}
	}
	//Valida las siglas
	for(ind=0;ind<this.siglas.length && !error;ind++){
		car = this.siglas.charAt(ind);
		if(!this.esAlfabetico(car) && !this.esEnie(car) && !this.esAmp(car)){
			error=true;
			alert(this.descripcion + "\nLas siglas de contribuyente contienen caracteres no permitidos.");
			break;
		}
	}
	
	//Valida la fecha
	if(!error && !this.esFechaValida(this.fecha)){
		error=true;
		if(this.persona == "PM")
			alert(this.descripcion + "\nLa fecha de inicio de actividades contiene caracteres no permitidos o es incorrecta.");
		else
			alert(this.descripcion + "\nLa fecha de nacimiento contiene caracteres no permitidos o es incorrecta.");
	}
	
	//Valida homoclave
	for(ind=0;ind<this.homo.length && !error;ind++){
		car = this.homo.charAt(ind);
		if(!this.esAlfabetico(car) && !this.esNumerico(car) && !this.esEnie(car)){
			error=true;
			alert(this.descripcion + "\nLa homonimia contiene caracteres no permitidos o es incorrecta.");
			break;
		}
	}
	if(!error && this.homo.length == 3){
		car = this.homo.charAt(2);
		if(!this.esAlfabetico(car) && !this.esNumerico(car) && !this.esEnie(car)){
			error=true;
			alert(this.descripcion + "\nEl d\u00edgito verificador contiene caracteres no permitidos o es incorrecto.");
		}		
	}
	
	this.errorValidacion = error;
	return error;
};

RFC.prototype.OnKeyUp = function(event){

	this.recuperarDeDestino();
	if(this.modo==MODO_TARJETA_TRIBUTARIA)
	{
		try
		{
			// Se ha le�do la Tarjeta Tributaria desde la banda magn�tica.
			if(this.valor.length>=92 && this.valor.indexOf('%')!=-1)
			{
				this.nombreTarjetaTrib = this.valor.substring(this.valor.indexOf('%')+15, this.valor.indexOf('%')+15+43).toUpperCase();
				this.ponerCampo(this.valor.substring(this.valor.indexOf('%')+1,this.valor.indexOf('%')+14).toUpperCase());
				this.procesoBandaOCodigo = true;
			}

			// Se ha le�do la Tarjeta Tributaria desde el c�digo de barras.
			else if(this.valor.length>=25)
			{
				this.ponerCampo(this.valor.substring(0,13).toUpperCase());
				this.nombreTarjetaTrib = "";
				this.procesoBandaOCodigo = true;
			}else
				this.procesoBandaOCodigo = false;
				

			/*
			if(this.valor.length!=0 || this.nombreTarjetaTrib.length!=0)
			{
				this.ponerEnDestino();
				this.habilitar(false);
			}
			*/
		}
		catch(Exception)
		{
		}
	}
};
//Fin Clase RFC//Clase CURP
//Elaborado por Jesus ALejandro Mendoza Loya jul2016
//

var CURP = function(valor, mascara, campoDestino){
	ElementoPago.call(this, valor, mascara, campoDestino);
	this.descripcion = 'CURP';
	this.longitudMin=18;
	this.longitudMax=18;
	this.claveInformatica = '10002';
	this.catalogoEntidad = ["AS","BC","BS","CC","CS","CH","CL","CM","DF","DG","GT","GR","HG","JC","MC","MN","MS","NT","NL","OC","PL","QT","QR","SP","SL","SR","TC","TS","TL","VZ","YN","ZS","NE"];
};
CURP.prototype = hereda(ElementoPago.prototype);
CURP.prototype.constructor = CURP;
CURP.prototype.ponerValor = function(valor){
	
	this.valor = valor;
	switch(this.valor.length)
	{
		case 18:
			this.siglas = this.valor.substring(0, 4);
			this.fecha = this.valor.substring(4, 10);
			this.sexo = this.valor.substring(10, 11);
			this.Entidad = this.valor.substring(11, 13);
			this.siglasDos = this.valor.substring(13, 16);
			this.homo = this.valor.substring(16, 17);
			this.digito = this.valor.substring(17, 18);
			break;
		default:
			this.siglas = '';
			this.fecha = '';
			this.sexo = '';
			this.Entidad = '';
			this.siglasDos = '';
			this.homo = '';
			this.digito = '';
			this.persona = '';
	}
	ElementoPago.prototype.ponerValor.call(this, valor);
	
};
CURP.prototype.validar = function(){
	var error = false;
	var car;
	var ind;

	if(this.valor.length!=18){
		alert(this.descripcion + "\nDebe contener 18 caracteres.");
		error = true;
	}
	if(!error){
		//Valida las siglas
		for(ind=0;ind<this.siglas.length;ind++){
			car = this.siglas.charAt(ind);
			if(!this.esAlfabetico(car) && !this.esEnie(car) && !this.esAmp(car)){
				error=true;
				alert(this.descripcion + "\nLas siglas contienen caracteres no permitidos.");
				break;
			}
		}
	}
	//Valida la fecha
	if(!error && !this.esFechaValida(this.fecha)){
		error=true;
		if(this.persona == "PM")
			alert(this.descripcion + "\nLa fecha de inicio de actividades contiene caracteres no permitidos o es incorrecta.");
		else
			alert(this.descripcion + "\nLa fecha de nacimiento contiene caracteres no permitidos o es incorrecta.");
	}
	
	//Valida el sexo
	if(!error && this.sexo !== 'M' && this.sexo !== 'H'){
		error=true;
		alert(this.descripcion + "\nLa sigla del sexo es incorrecta.");
	}
	
	//Valida la Entidad Federativa
	if(!error && this.catalogoEntidad.indexOf(this.Entidad)<0){
		error=true;
		alert(this.descripcion + "\nLa entidad federativa de nacimiento contiene caracteres no permitidos o es incorrecta.");
	}
		
	//Valida la siglasDos
	if(!error){
	for(ind=0;ind<this.siglasDos.length;ind++){
		car = this.siglasDos.charAt(ind);
		if(!this.esAlfabetico(car) && !this.esEnie(car) && !this.esAmp(car)){
			error=true;
			alert(this.descripcion + "\nContiene caracteres no permitidos.");
			break;
		}
	}
	}
	
	//Valida homoclave
	if(!error){
	for(ind=0;ind<this.homo.length;ind++){
		car = this.homo.charAt(ind);
		if(!this.esAlfabetico(car) && !this.esNumerico(car) && !this.esEnie(car)){
			error=true;
			alert(this.descripcion + "\nLa homoclave contiene caracteres no permitidos o es incorrecta.");
			break;
		}
	}
	}
	
	if(this.homo.length == 3){
		car = this.homo.charAt(2);
		if(!this.esAlfabetico(car) && !this.esNumerico(car) && !this.esEnie(car)){
			error=true;
			alert(this.descripcion + "\nEl d\u00edgito verificador contiene caracteres no permitidos o es incorrecto.");
		}		
	}
	
	//Valida la digito
	if(!error){
		for(ind=0;ind<this.digito.length;ind++){
			car = this.digito.charAt(ind);
			if(!this.esNumerico(car)){
				error=true;
				alert(this.descripcion + "\nEl d\u00edgito verificador contiene caracteres no permitidos o es incorrecto.");
				break;
				
			}
		}
	}

	
	this.errorValidacion = error;
	return error;
};

CURP.prototype.onKeyyPress = function(event){
	var res = false;

	var tecla = ('which' in event) ? event.which : event.keyCode;
	var car = String.fromCharCode(tecla).toUpperCase();
	var code = event.charCode;
	if((res=ElementoPago.prototype.onKeyyPress(event))==false)
		res = (this.esEnie(car) || this.esAmp(car) || this.esNumerico(car) || this.esAlfabetico(car));

	return res;
};
//Fin Clase CURP//Clase Apellido
//Elaborado por Jesus ALejandro Mendoza Loya jul2016
//

var Apellido = function(valor, mascara, campoDestino){
	if(campoDestino === 'textfieldPaterno')
	{
		ElementoPago.call(this, valor, mascara, campoDestino);
		this.descripcion = 'Apellido paterno';
		this.claveInformatica = '10003';
	}
	else
	{
		ElementoPago.call(this, valor, mascara, campoDestino);
		this.descripcion = 'Apellido materno';
		this.claveInformatica = '10004';
	}
	this.longitudMin=0;
	this.longitudMax=60;
};

Apellido.prototype = hereda(ElementoPago.prototype);
Apellido.prototype.constructor = Apellido;
Apellido.prototype.ponerValor = function(valor){
	ElementoPago.prototype.ponerValor.call(this, valor);
};
Apellido.prototype.validar = function(){
	var error = false;
	var car;
	var ind;

	if(this.obligatorio && this.valor.length == 0){
		error = true;
		alert(this.descripcion + "\nNo puede ser nulo.");
	}
	if(!error){
		//Valida las caracteres permitidos
		for(ind=0;ind<this.valor.length;ind++){
			car = this.valor.charAt(ind);
			
			if(!this.esAlfabetico(car) && !this.esEnie(car) && !this.esAmp(car) && !this.esEspacio(car) && !this.esApostrofe(car)){
				error=true;
				alert(this.descripcion + "\nContiene caracteres no permitidos.");
				break;
			}
		}
	}
	
	this.errorValidacion = error;
	return error;
};

Apellido.prototype.onKeyyPress = function(event){
	var res = false;

	var tecla = ('which' in event) ? event.which : event.keyCode;
	var car = String.fromCharCode(tecla).toUpperCase();
	var code = event.charCode;
	
	if((res=ElementoPago.prototype.onKeyyPress(event))==false)
		res = (this.esEnie(car) || this.esAmp(car) || this.esApostrofe(car) || this.esEspacio(car) || this.esAlfabetico(car));

	return res;
};

Apellido.prototype.onChange = function(event){
	var res = true;

	this.recuperarDeDestino();
	this.ponerCampo(this.valor.trim());

	return res;
};

//Fin Clase Apellido//Clase Nombre
//Elaborado por Jesus ALejandro Mendoza Loya jul2016
//

var NOMBRE = function(valor, mascara, campoDestino){
	ElementoPago.call(this, valor, mascara, campoDestino);
	this.descripcion = 'Nombre';
	this.longitudMin=60;
	this.claveInformatica = '10005';
};

NOMBRE.prototype = hereda(ElementoPago.prototype);
NOMBRE.prototype.constructor = NOMBRE;
NOMBRE.prototype.ponerValor = function(valor){

ElementoPago.prototype.ponerValor.call(this, valor);
};
NOMBRE.prototype.validar = function(){
	var error = false;
	var car;
	var ind;

	if(this.obligatorio && this.valor.length == 0){
		error = true;
		alert(this.descripcion + "\nNo puede ser nulo.");
	}
	
	if(!error){
	//Valida las siglas
	for(ind=0;ind<this.valor.length;ind++){
		car = this.valor.charAt(ind);
		if(!this.esAlfabetico(car) && !this.esEnie(car) && !this.esAmp(car) && !this.esPunto(car) && !this.esEspacio(car) && !this.esApostrofe(car)){
			error=true;
			alert(this.descripcion + "\nContiene caracteres no permitidos.");
			break;
		}
	}
	}
	
	this.errorValidacion = error;
	return error;
};

NOMBRE.prototype.onKeyyPress = function(event){
	var res = false;

	var tecla = ('which' in event) ? event.which : event.keyCode;
	var car = String.fromCharCode(tecla).toUpperCase();
	var code = event.charCode;
	
	if((res=ElementoPago.prototype.onKeyyPress(event))==false)
		res = (this.esEnie(car) || this.esPunto(car) || this.esEspacio(car) || this.esAmp(car) || this.esApostrofe(car) || this.esAlfabetico(car));

	return res;
};

NOMBRE.prototype.onChange = function(event){
	var res = true;

	this.recuperarDeDestino();
	this.ponerCampo(this.valor.trim());

	return res;
};
//Fin Clase Nombre//Clase Razon
//Elaborado por Jesus ALejandro Mendoza Loya jul2016
//

var RAZON = function(valor, mascara, campoDestino){
	ElementoPago.call(this, valor, mascara, campoDestino);
	this.descripcion = 'Denominaci\u00f3n o raz\u00f3n social';
	this.longitudMin=60;
	this.longitudMax=60;
	this.claveInformatica = '10006';
};

RAZON.prototype = hereda(ElementoPago.prototype);
RAZON.prototype.constructor = RAZON;
RAZON.prototype.ponerValor = function(valor){

ElementoPago.prototype.ponerValor.call(this, valor);
};
RAZON.prototype.validar = function(){
	var error = false;
	var car;
	var ind;

	if(!error){
		for(ind=0;ind<this.valor.length;ind++){
			car = this.valor.charAt(ind);
			if(!this.esAlfabetico(car) && !this.esNumerico(car) && !this.esEspecialRazonSocial(car)  && !this.esEnie(car) && !this.esEspacio(car) && !this.esApostrofe(car) && !this.esAmp(car)){
				error=true;
				alert(this.descripcion + "\nLas siglas contienen caracteres no permitidos.");
				break;
			}
		}
	}

	if(!error){
		if(this.obligatorio && this.valor.length==0){
			this.errorValidacion = true;
			throw this.descripcion + "\n No puede ser nulo.";
		}
	}
	this.errorValidacion = error;
	return error;
};

RAZON.prototype.onKeyyPress = function(event){
	var res = false;

	var tecla = ('which' in event) ? event.which : event.keyCode;
	var car = String.fromCharCode(tecla).toUpperCase();
	var code = event.charCode;
	
	if((res=ElementoPago.prototype.onKeyyPress(event))==false)
		res = (this.esEspecialRazonSocial(car) || this.esEnie(car) || this.esEspacio(car) || this.esAmp(car) || this.esApostrofe(car) || this.esAlfabetico(car) || this.esNumerico(car));

	return res;
};

RAZON.prototype.onChange = function(event){
	var res = true;

	this.recuperarDeDestino();
	this.ponerCampo(this.valor.trim());

	return res;
};

//Fin Clase Razon//Clase Dependencia
//Elaborado por David Aguilera Cuevas jul2016
//

var Dependencia = function(valor, mascara, campoDestino){
	
	ElementoPago.call(this, valor, mascara, campoDestino);
	this.descripcion = 'Dependencia';
	this.claveInformatica = '30005';
	this.cargarCatalogos();
	this.indice = 0;
	
};
Dependencia.prototype = hereda(ElementoPago.prototype);
Dependencia.prototype.constructor = Dependencia;
Dependencia.prototype.cargarCatalogos = function(){
	var ind;
	
	this.claveCatalogo = ["01","02","03","04",/*"05",*/"06","07","08","09","10","11","12",/*"13",*/"14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","32","33","34","35","36","37","38","39","40","41","42","43","44","45","46","47","48","49","50","51","52","53","54","55","56","57","58","59","60","61","62","63","64","65","66","67","68","69","70","71","72","73","74","75","76","77","78","79","80","81","82","83","84","85","86","87","88","89","90","91","99"];
	this.descripCatalogo = [
	                		"01 Secretar\u00eda de Gobernaci\u00f3n",
	                		"02 Secretar\u00eda de Relaciones Exteriores",
	                		"03 Secretar\u00eda de la Defensa Nacional",
	                		"04 Secretar\u00eda de Marina",
//	                		"05 Secretar\u00eda de Seguridad P\u00fablica",
	                		"06 Secretar\u00eda de Hacienda y Cr\u00e9dito P\u00fablico",
	                		"07 Secretar\u00eda de Desarrollo Social",
	                		"08 Secretar\u00eda de Medio Ambiente y Recursos Naturales",
	                		"09 Secretar\u00eda de Energ\u00eda",
	                		"10 Secretar\u00eda de Econom\u00eda",
	                		"11 Secretar\u00eda de Agricultura, Ganader\u00eda, Desarrollo Rural, Pesca y Alimentaci\u00f3n",
	                		"12 Secretar\u00eda de Comunicaciones y Transportes",
//	                		"13 Secretar\u00eda de la Funci\u00f3n P\u00fablica",
	                		"14 Secretar\u00eda de Educaci\u00f3n P\u00fablica",
	                		"15 Secretar\u00eda de Salud",
	                		"16 Secretar\u00eda del Trabajo y Previsi\u00f3n Social",
	                		"17 Secretar\u00eda de Desarrollo Agrario, Territorial y Urbano",
	                		"18 Secretar\u00eda de Turismo",
	                		"19 Consejer\u00eda Jur\u00eddica del Ejecutivo Federal",
	                		"20 Archivo General de la Naci\u00f3n",
	                		"21 Comisi\u00f3n Calificadora de Publicaciones y Revistas Ilustradas",
	                		"22 Instituto Nacional de Migraci\u00f3n",
	                		"23 Prevenci\u00f3n y Readaptaci\u00f3n Social",
	                		"24 Comisi\u00f3n Nacional Bancaria y de Valores",
	                		"25 Comisi\u00f3n Nacional de Seguros y Fianzas",
	                		"26 Comisi\u00f3n Nacional del Sistema de Ahorro para el Retiro",
	                		"27 Comisi\u00f3n Nacional del Agua",
	                		"28 Servicio de Administraci\u00f3n Tributaria",
	                		"29 Instituto Nacional de Ecolog\u00eda",
	                		"30 Comisi\u00f3n Nacional de \u00c1reas Naturales Protegidas",
	                		"31 Procuradur\u00eda Federal de Protecci\u00f3n al Ambiente",
	                		"32 Comisi\u00f3n Nacional Forestal",
	                		"33 Comisi\u00f3n Nacional de Seguridad Nuclear y Salvaguardias",
	                		"34 Comisi\u00f3n Nacional para el Uso Eficiente de la Energ\u00eda",
	                		"35 Comisi\u00f3n Reguladora de Energ\u00eda",
	                		"36 Comisi\u00f3n Federal de Competencia",
	                		"37 Comisi\u00f3n Federal de Mejora Regulatoria",
	                		"38 Fondo Nacional de Apoyo para las Empresas en Solidaridad",
	                		"39 Instituto Mexicano de la Propiedad Industrial",
	                		"40 Procuradur\u00eda Federal del Consumidor",
	                		"41 Agencia de Servicios a la Comercializaci\u00f3n y Desarrollo de Mercados Agropecuarios",
	                		"42 Comisi\u00f3n Nacional de Acuacultura y Pesca",
	                		"43 Servicio de Informaci\u00f3n Agroalimentaria y Pesquera",
	                		"44 Servicio Nacional de Inspecci\u00f3n y Certificaci\u00f3n de Semillas",
	                		"45 Servicio Nacional de Sanidad, Inocuidad y Calidad Agroalimentaria",
	                		"46 Comisi\u00f3n Federal de Telecomunicaciones",
	                		"47 Servicios a la Navegaci\u00f3n en el Espacio A\u00e9reo Mexicano",
	                		"48 Caminos y Puentes Federales de Ingresos y Servicios Conexos",
	                		"49 Instituto de Administraci\u00f3n y Aval\u00faos de Bienes Nacionales",
	                		"50 Consejo Nacional para la Cultura y las Artes",
	                		"51 Instituto Nacional del Derecho de Autor",
	                		"52 Instituto Nacional de Bellas Artes y Literatura",
	                		"53 Comisi\u00f3n Nacional de Cultura F\u00edsica y Deporte",
	                		"54 Universidad Pedag\u00f3gica Nacional",
	                		"55 Comisi\u00f3n Federal para la Protecci\u00f3n contra Riesgos Sanitarios",
	                		"56 Instituto Polit\u00e9cnico Nacional",
	                		"57 Centro Nacional de la Transfusi\u00f3n Sangu\u00ednea",
	                		"58 Procuradur\u00eda Federal de la Defensa del Trabajo",
	                		"59 Comit\u00e9 Nacional Mixto de Protecci\u00f3n al Salario",
	                		"60 Instituto Federal de Acceso a la Informaci\u00f3n y Protecci\u00f3n de Datos",
	                		"61 Comisi\u00f3n Nacional para el Desarrollo de los Pueblos Ind\u00edgenas",
	                		"62 Procuradur\u00eda General de la Rep\u00fablica",
	                		"63 Tribunal Federal de Justicia Fiscal y Administrativa",
	                		"64 Poder Judicial",
	                		"65 Servicio de Administraci\u00f3n y Enajenaci\u00f3n de Bienes",
	                		"66 Centro de Investigaci\u00f3n y Seguridad Nacional",
	                		"67 Consejo Nacional de Poblaci\u00f3n",
	                		"68 Centro de Producci\u00f3n de Programas Informativos y Especiales",
	                		"69 Consejo Nacional para Prevenir la Discriminaci\u00f3n",
	                		"70 Polic\u00eda Federal",
	                		"71 Servicio de Protecci\u00f3n Federal",
	                		"72 Colegio Superior Agropecuario del Estado de Guerrero",
	                		"73 Instituto Nacional de la Pesca",
	                		"74 Comisi\u00f3n Nacional de las Zonas \u00c1ridas",
	                		"75 Instituto Mexicano del Transporte",
	                		"76 Administraci\u00f3n Federal de Servicios Educativos en el Distrito Federal",
	                		"77 Comisi\u00f3n de Apelaci\u00f3n y Arbitraje del Deporte",
	                		"78 Instituto Nacional de Antropolog\u00eda e Historia",
	                		"79 Radio Educaci\u00f3n",
	                		"80 Instituto Nacional de Estudios Hist\u00f3ricos de las Revoluciones de M\u00e9xico",
	                		"81 Servicios de Atenci\u00f3n Psiqui\u00e1trica",
	                		"82 Comisi\u00f3n Nacional de los Salarios M\u00ednimos",
	                		"83 Comisi\u00f3n Nacional de Hidrocarburos",
	                		"84 Comisi\u00f3n Federal de Electricidad",
	                		"85 Instituto Nacional de Desarrollo Social",
	                		"86 Coordinaci\u00f3n Nacional del Programa de Desarrollo Humano Oportunidades",
	                		"87 Registro Agrario Nacional",
	                		"88 Procuradur\u00eda Agraria",
	                		"89 Tribunal Superior Agrario",
	                		"90 Instituto Federal Electoral",
	                		"91 Oficina de la Presidencia de la Rep\u00fablica",
	                		"99 Otra"
	                        ];
	var cbDestino = document.getElementById(this.campoDestino);
	var opciones = cbDestino.options.length;
	for(ind=0;ind<opciones;ind++){
		cbDestino.remove(0);
	}
	
	
	for(ind=0;ind<this.descripCatalogo.length;ind++){
		var opcion = document.createElement('option');
        opcion.text = this.descripCatalogo[ind];
        opcion.value = this.descripCatalogo[ind];
		cbDestino.add(opcion, ind);
	}
	
};
Dependencia.prototype.ponerValor = function(valor){
	this.valor = valor;
	ElementoPago.prototype.ponerValor.call(this, valor);
};
Dependencia.prototype.recuperarDeDestino = function(){
	var cbDestino = document.getElementById(this.campoDestino);
	this.ponerValor(this.claveCatalogo[cbDestino.selectedIndex]);
	this.indice = cbDestino.selectedIndex;
};
Dependencia.prototype.OnChange = function(){
	this.recuperarDeDestino();
};
Dependencia.prototype.validar = function(){
	var error = false;

	this.errorValidacion = error;
	return error;
};

Dependencia.prototype.valorParaImpresion = function(){
	var res = '';
	if(this.valor.length>0){
		
		res = this.descripCatalogo[this.indice];
	}
	
	return res;
};

Dependencia.prototype.seleccionar = function(elemento){
	var cbDestino = document.getElementById(this.campoDestino);
	cbDestino.selectedIndex = elemento;
	this.ponerValor(this.claveCatalogo[cbDestino.selectedIndex]);
	this.indice = cbDestino.selectedIndex;
};
//Fin Clase Dependencia//Clase Ejercicio
//Elaborado por David Aguilera Cuevas jul2016
//

var Ejercicio = function(valor, mascara, campoDestino){
	ElementoPago.call(this, valor, mascara, campoDestino);
	this.descripcion = 'Ejercicio';
	this.claveInformatica = '14727';
	var parts = PARAM_FECHA_ACTUAL.split("/");
	this.anioInicial = 1993;
	this.anioFinal = parseInt(parts[2], 10);
	this.cargarCatalogo();
	this.indice = 0;
	this.periodo=null;
	this.periodicidad = null;
	this.contenedor = null;
};
Ejercicio.prototype = hereda(ElementoPago.prototype);
Ejercicio.prototype.constructor = Ejercicio;
Ejercicio.prototype.ponerValor = function(valor){
	
	this.valor = valor;
	ElementoPago.prototype.ponerValor.call(this, valor);
};
Ejercicio.prototype.cargarCatalogo = function(){
	var ind;
	
	var cbDestino = document.getElementById(this.campoDestino);
	var opciones = cbDestino.options.length;
	for(ind=0;ind<opciones;ind++){
		cbDestino.remove(0);
	}
	
	for(ind=this.anioInicial;ind<=this.anioFinal;ind++){
		var opcion = document.createElement('option');
        opcion.text = ind;
        opcion.value = ind;
		cbDestino.add(opcion, ind);
	}
	
	cbDestino.selectedIndex = cbDestino.options.length - 1;
	this.OnChange();
};
Ejercicio.prototype.recuperarDeDestino = function(){
	var cbDestino = document.getElementById(this.campoDestino);
	this.ponerValor(cbDestino.options[cbDestino.selectedIndex].text);
	this.indice = cbDestino.selectedIndex;
};

Ejercicio.prototype.OnChange = function(){
	this.recuperarDeDestino();
	if(typeof this.periodo!=='undefined' && this.periodo !==null )
		this.periodo.cargarCatalogo(this.periodicidad, this.valor);

	if(typeof this.contenedor !=='undefined')
		this.contenedor.reiniciarImportes();
};

Ejercicio.prototype.validar = function(){
	var error = false;

	this.errorValidacion = error;
	return error;
};
//Fin Ejercicio//Clase Periodo
//Elaborado por David Aguilera Cuevas jul2016
//

var Periodo = function(valor, mascara, campoDestino){
	ElementoPago.call(this, valor, mascara, campoDestino);
	this.descripcion = 'Per\u00edodo';
	this.claveInformatica = '14702';
	
	this.llaveMes = ["1","2","3","4","5","6","7","8","9","10","11","12"];
	this.meses = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];
	this.llaveBimestre = ["36","37","38","39","40","41"];
	this.bimestres = ["Enero-Febrero","Marzo-Abril","Mayo-Junio","Julio-Agosto","Septiembre-Octubre","Noviembre-Diciembre"];
	this.llaveTrimestre = ["13","14","15","16"];
	this.trimestres = ["Enero-Marzo","Abril-Junio","Julio-Septiembre","Octubre-Diciembre"];
	this.llaveCuatrimestre = ["17","18","19"];
	this.cuatrimestres = ["Enero-Abril","Mayo-Agosto","Septiembre-Diciembre"];
	this.llaveSemestre = ["20","21"];
	this.semestres = ["Enero-Junio","Julio-Diciembre"];
	this.llaveDelEjer = ["35"];
	this.delEjercicio = ["Del Ejercicio"];
	this.periodicidad = "Mensual";
	this.indice = -1;
	this.contenedor = null;
};
Periodo.prototype = hereda(ElementoPago.prototype);
Periodo.prototype.constructor = Periodo;
Periodo.prototype.ponerValor = function(valor){
	
	this.valor = valor;
	ElementoPago.prototype.ponerValor.call(this, valor);
};
Periodo.prototype.cargarCatalogo = function(periodicidad, anioStr){
	var ind;
	var anio = parseInt(anioStr, 10);
	var cbDestino = document.getElementById(this.campoDestino);
	var opciones = cbDestino.options.length;
	for(ind=0;ind<opciones;ind++){
		cbDestino.remove(0);
	}
	this.periodicidad = periodicidad;
	var parts = PARAM_FECHA_ACTUAL.split("/");
	var fecha = new Date(parseInt(parts[2], 10),
	                  parseInt(parts[1], 10) - 1,
	                  parseInt(parts[0], 10));

	
	if(this.periodicidad.indexOf("Mensual")>=0){
		var mesFin = 11;
		if(anio == parseInt(parts[2], 10))
			mesFin = parseInt(parts[1], 10) - 1;
		for(ind=0;ind<=mesFin;ind++){
			var opcion = document.createElement('option');
	        opcion.text = this.meses[ind];
	        opcion.value = this.meses[ind];
			cbDestino.add(opcion, ind);
		}
		if(anio<parseInt(parts[2], 10))
			cbDestino.selectedIndex = 0;
		else
			cbDestino.selectedIndex = fecha.getMonth();
		this.indice = cbDestino.selectedIndex;
		this.periodicidad='Mensual';
	}else if(this.periodicidad.indexOf("Bimestral")>=0){
		var numBim = 5;
		if(anio == parseInt(parts[2], 10))
			numBim = parseInt((parts[1] / 2) + 0.5, 10) - 1;
		for(ind=0;ind<=numBim;ind++){
			var opcion = document.createElement('option');
	        opcion.text = this.bimestres[ind];
	        opcion.value = this.bimestres[ind];
			cbDestino.add(opcion, ind);
		}
		this.periodicidad='Bimestral';
		if(anio<parseInt(parts[2], 10))
			cbDestino.selectedIndex = 0;
		else
			cbDestino.selectedIndex = numBim;
		this.indice = cbDestino.selectedIndex;
	}else if(this.periodicidad.indexOf("Trimestral")>=0){
		var numTrim = 3;
		if(anio == parseInt(parts[2], 10))
			numTrim = parseInt((parts[1] / 3) + 0.666667, 10) - 1;
		for(ind=0;ind<=numTrim;ind++){
			var opcion = document.createElement('option');
	        opcion.text = this.trimestres[ind];
	        opcion.value = this.trimestres[ind];
			cbDestino.add(opcion, ind);
		}
		this.periodicidad='Trimestral';
		if(anio<parseInt(parts[2], 10))
			cbDestino.selectedIndex = 0;
		else
			cbDestino.selectedIndex = numTrim;
		this.indice = cbDestino.selectedIndex;
	}else if(this.periodicidad.indexOf("Cuatrimestral")>=0){
		var numCuatrim = 2;
		if(anio == parseInt(parts[2], 10))
			numCuatrim = parseInt((parts[1] / 4) + 0.75, 10) - 1;
		for(ind=0;ind<=numCuatrim;ind++){
			var opcion = document.createElement('option');
	        opcion.text = this.cuatrimestres[ind];
	        opcion.value = this.cuatrimestres[ind];
			cbDestino.add(opcion, ind);
		}
		this.periodicidad='Cuatrimestral';
		if(anio<parseInt(parts[2], 10))
			cbDestino.selectedIndex = 0;
		else
			cbDestino.selectedIndex = numCuatrim;
		this.indice = cbDestino.selectedIndex;
	}else if(this.periodicidad.indexOf("Semestral")>=0){
		var numSem = 1;
		if(anio == parseInt(parts[2], 10))
			numSem = parseInt((parts[1] / 6) + 0.833334, 10) - 1;
		for(ind=0;ind<=numSem;ind++){
			var opcion = document.createElement('option');
	        opcion.text = this.semestres[ind];
	        opcion.value = this.semestres[ind];
			cbDestino.add(opcion, ind);
		}
		this.periodicidad='Semestral';
		if(anio<parseInt(parts[2], 10))
			cbDestino.selectedIndex = 0;
		else
			cbDestino.selectedIndex = numSem;
		this.indice = cbDestino.selectedIndex;
	}else if(this.periodicidad.indexOf("DelEjercicio")>=0){
		this.periodicidad='DelEjercicio';
		var opcion = document.createElement('option');
        opcion.text = this.delEjercicio[0];
        opcion.value = this.delEjercicio[0];
		cbDestino.add(opcion, ind);
		cbDestino.selectedIndex = 0;
		this.indice = cbDestino.selectedIndex;
	}
	
	this.OnChange();
};
Periodo.prototype.recuperarDeDestino = function(){
	var cbDestino = document.getElementById(this.campoDestino);
	this.indice = cbDestino.selectedIndex;
	
	if(this.periodicidad.indexOf("Mensual")>=0){
		this.ponerValor(this.llaveMes[this.indice]);
	}else if(this.periodicidad.indexOf("Bimestral")>=0){
		this.ponerValor(this.llaveBimestre[this.indice]);
	}else if(this.periodicidad.indexOf("Trimestral")>=0){
		this.ponerValor(this.llaveTrimestre[this.indice]);
	}else if(this.periodicidad.indexOf("Cuatrimestral")>=0){
		this.ponerValor(this.llaveCuatrimestre[this.indice]);
	}else if(this.periodicidad.indexOf("Semestral")>=0){
		this.ponerValor(this.llaveSemestre[this.indice]);
	}else if(this.periodicidad.indexOf("DelEjercicio")>=0){
		this.ponerValor(this.llaveDelEjer[this.indice]);
	}
};

Periodo.prototype.recuperarNombre = function(){
	var res = '';
	
	if(this.periodicidad.indexOf("Mensual")>=0){
		res = this.meses[this.indice];
	}else if(this.periodicidad.indexOf("Bimestral")>=0){
		res = this.bimestres[this.indice];
	}else if(this.periodicidad.indexOf("Trimestral")>=0){
		res = this.trimestres[this.indice];
	}else if(this.periodicidad.indexOf("Cuatrimestral")>=0){
		res = this.cuatrimestres[this.indice];
	}else if(this.periodicidad.indexOf("Semestral")>=0){
		res = this.semestres[this.indice];
	}else if(this.periodicidad.indexOf("DelEjercicio")>=0){
		res = this.delEjercicio[this.indice];
	}
	
	return res;
};

Periodo.prototype.OnChange = function(){
	this.recuperarDeDestino();

	if(typeof this.contenedor !=='undefined')
		this.contenedor.reiniciarImportes();
};

Periodo.prototype.validar = function(){
	var error = false;

	this.errorValidacion = error;
	return error;
};
//Fin Periodo//Clase Periodicidad
//Elaborado por David Aguilera Cuevas jul2016
//

var Periodicidad = function(valor, mascara, campoDestino){
	ElementoPago.call(this, valor, mascara, campoDestino);
	this.descripcion = 'Per\u00edodo';
	this.longitudMin=1;
	this.longitudMax=1;
	this.claveInformatica = '14702';
	this.ponerValor(valor);
	this.ejercicio = null;
	this.periodo = null;
};
Periodicidad.prototype = hereda(ElementoPago.prototype);
Periodicidad.prototype.constructor = Periodicidad;
Periodicidad.prototype.ponerValor = function(valor){
	this.valor = valor;
	var radiobutton = document.getElementById(this.campoDestino);
	if(typeof radiobutton !== 'undefined'){
		if(valor==="checked")
			radiobutton.checked = true;
		else if (valor==="unchecked")
			radiobutton.checked = false;
	}
};
Periodicidad.prototype.recuperarDeDestino = function(){
	var radiobutton = document.getElementById(this.campoDestino);
	if(typeof radiobutton !== 'undefined'){
		if(radiobutton.checked == true)
			this.valor="checked";
		else if (radiobutton.checked == false)
			this.valor="unchecked";
	}
};
Periodicidad.prototype.OnChange = function(numero){

	this.recuperarDeDestino();
	if(this.valor==="checked" && this.ejercicio !== null){
		this.ejercicio.cargarCatalogo();
		this.ejercicio.periodicidad = this.campoDestino;
	}
	if(this.valor==="checked" && this.periodo !== null){
		this.periodo.cargarCatalogo(this.campoDestino, this.ejercicio.valor);
	}
};
//Fin Clase Periodicidad//Clase NoAplicaPeriodo
//Elaborado por David Aguilera Cuevas jul2016
//

var NoAplicaPeriodo = function(valor, mascara, campoDestino){
	ElementoPago.call(this, valor, mascara, campoDestino);
	this.descripcion = 'No aplica periodo';
	this.longitudMin=1;
	this.longitudMax=1;
	this.claveInformatica = '';
	this.tiposPeriodo = new Array();
	this.tiposPeriodo[0] = new Periodicidad('unchecked','','radiobuttonMensual');
	this.tiposPeriodo[1] = new Periodicidad('unchecked','','radiobuttonBimestral');
	this.tiposPeriodo[2] = new Periodicidad('unchecked','','radiobuttonTrimestral');
	this.tiposPeriodo[3] = new Periodicidad('unchecked','','radiobuttonCuatrimestral');
	this.tiposPeriodo[4] = new Periodicidad('unchecked','','radiobuttonSemestral');
	this.tiposPeriodo[5] = new Periodicidad('unchecked','','radiobuttonDelEjercicio');
};
NoAplicaPeriodo.prototype = hereda(ElementoPago.prototype);
NoAplicaPeriodo.prototype.constructor = NoAplicaPeriodo;
NoAplicaPeriodo.prototype.ponerValor = function(valor){
	
	this.valor = valor;
	
	var checkbox = document.getElementById(this.campoDestino);
	if(typeof checkbox !== 'undefined'){
		if(valor==="checked")
			checkbox.checked = true;
		else if (valor==="unchecked")
			checkbox.checked = false;
	}
	
};
NoAplicaPeriodo.prototype.recuperarDeDestino = function(){
	var checkbox = document.getElementById(this.campoDestino);
	if(typeof checkbox !== 'undefined'){
		if(checkbox.checked == true)
			this.valor="checked";
		else if (checkbox.checked == false)
			this.valor="unchecked";
	}
};
NoAplicaPeriodo.prototype.OnClick = function(){
	this.recuperarDeDestino();

	var checkbox = document.getElementById(this.campoDestino);
	if(!checkbox.disabled){
		if(this.valor==="checked"){
			//Limpia y deshabilita radiobuttons
			for(var ind=0;ind<this.tiposPeriodo.length;ind++){
				var periodicidad = this.tiposPeriodo[ind];
				periodicidad.ponerValor("unchecked");
				periodicidad.habilitar(false);
			}
			//Deshabilita ejercicio
			if(this.tiposPeriodo[0].ejercicio)
				this.tiposPeriodo[0].ejercicio.habilitar(false);
			//Deshabilita periodo
			if(this.tiposPeriodo[0].periodo)
				this.tiposPeriodo[0].periodo.habilitar(false);
			
		}else{
			//Habilita radiobuttons
			for(var ind=0;ind<this.tiposPeriodo.length;ind++){
				var periodicidad = this.tiposPeriodo[ind];
				periodicidad.ponerValor("unchecked");
				periodicidad.habilitar(true);
			}
			//Habilita ejercicio
			if(this.tiposPeriodo[0].ejercicio)
				this.tiposPeriodo[0].ejercicio.habilitar(true);
			//Habilita periodo
			if(this.tiposPeriodo[0].periodo)
				this.tiposPeriodo[0].periodo.habilitar(true);
		}
	}
	//Pone el mensual como default
	var mensual = this.tiposPeriodo[0];
	mensual.ponerValor("checked");
	mensual.OnChange(0);
};
NoAplicaPeriodo.prototype.periodoSeleccionado = function(){
	var res = null;
	for(var ind=0;ind<this.tiposPeriodo.length;ind++){
		var periodicidad = this.tiposPeriodo[ind];
		if(periodicidad.valor ==="checked"){
			return periodicidad;
		}
	}
	
	return res;
};
NoAplicaPeriodo.prototype.ponerEjercicio = function(ejercicio){
	for(var ind=0;ind<this.tiposPeriodo.length;ind++){
		var periodicidad = this.tiposPeriodo[ind];
		periodicidad.ejercicio = ejercicio;
	}
	//Pone el mensual como default
	var mensual = this.tiposPeriodo[0];
	mensual.ponerValor("checked");
	mensual.OnChange(0);
};
NoAplicaPeriodo.prototype.ponerPeriodo = function(periodo){
	for(var ind=0;ind<this.tiposPeriodo.length;ind++){
		var periodicidad = this.tiposPeriodo[ind];
		periodicidad.periodo = periodo;
	}
	//Pone el mensual como default
	var mensual = this.tiposPeriodo[0];
	mensual.ponerValor("checked");
	mensual.OnChange(0);
};
NoAplicaPeriodo.prototype.ponerCampo = function(valor){
	var destino = document.getElementById(this.campoDestino);
	if(destino !== null){
		if(valor==='checked'){
			this.ponerValor(valor);
			destino.checked = true;
		}
		else{
			this.ponerValor('unchecked');
			destino.checked = false;
		}
	}
};
//Fin Clase NoAplicaPeriodo//Clase CadenaDependencia
//Elaborado por David Aguilera Cuevas jul2016
//

var CadenaDependencia = function(valor, mascara, campoDestino){
	ElementoPago.call(this, valor, mascara, campoDestino);
	this.descripcion = 'Cadena de la Dependencia';
	this.longitudMin=14;
	this.longitudMax=14;
	this.claveInformatica = '30007';
	
};
CadenaDependencia.prototype = hereda(ElementoPago.prototype);
CadenaDependencia.prototype.constructor = CadenaDependencia;
CadenaDependencia.prototype.ponerValor = function(valor){
	
	this.valor = valor;

	ElementoPago.prototype.ponerValor.call(this, valor);
};
CadenaDependencia.prototype.validar = function(){
	var error = false;

	if(!error){
		if(this.valor.length>=this.longitudMin && this.valor.length==this.longitudMax)
			;
		else{
			error=true;
			alert(this.descripcion + "\nNo puede ser nulo y debe contener 14 carateres.");
		}
	}

	for(var ind=0;ind < this.valor.length;ind++){
		var car = this.valor.charAt(ind);
		if(!this.esAlfabetico(car) && !this.esNumerico(car)){
			error = true;
			alert(this.descripcion + "\nContiene carateres no permitidos.");
			break;
		}
	}
	
	this.errorValidacion = error;
	return error;
};

CadenaDependencia.prototype.onChange = function(event){
	var res = true;

	this.recuperarDeDestino();
	this.ponerCampo(this.valor.trim());

	return res;
};

//Fin ClaveRefDPA//Clase ClaveContable
//Elaborado por David Aguilera Cuevas jul2016
//

var ClaveContable = function(valor, mascara, campoDestino){
	Numerico.call(this, valor, mascara, campoDestino);
	this.descripcion = 'Clave Contable';
	this.longitudMin=6;
	this.longitudMax=6;
	this.claveInformatica = '30006';
	
};
ClaveContable.prototype = hereda(Numerico.prototype);
ClaveContable.prototype.constructor = ClaveContable;
ClaveContable.prototype.ponerValor = function(valor){
	
	this.valor = valor;
	ElementoPago.prototype.ponerValor.call(this, valor);
	
};
ClaveContable.prototype.validar = function(){
	var error = false;

	/*
	//Valida que campo tenga un valor
	if(this.valor === ''){
		error=true;
		alert(this.descripcion + "\nNo puede ser nulo.");
	}
	*/
	
	this.errorValidacion = error;
	return error;
};
//Fin ClaveContable
//Clase ClaveRefDPA
//Elaborado por David Aguilera Cuevas jul2016
//

var ClaveRefDPA = function(valor, mascara, campoDestino){
	Numerico.call(this, valor, mascara, campoDestino);
	this.contenedor = null;
	this.descripcion = 'Clave de Referencia del DPA';
	this.longitudMin=9;
	this.longitudMax=9;
	this.claveInformatica = '';
	this.claveContable = new ClaveContable('', '999999', '');
	this.caracter3='0';
};
ClaveRefDPA.prototype = hereda(Numerico.prototype);
ClaveRefDPA.prototype.constructor = ClaveRefDPA;
ClaveRefDPA.prototype.ponerValor = function(valor){
	
	this.valor = valor;

	if(this.valor.length == this.longitudMax)
		this.claveContable.ponerValor(this.valor.substring(2,8));
	/*else
		this.claveContable.ponerValor(this.valor);
	*/
	
	ElementoPago.prototype.ponerValor.call(this, valor);
};
ClaveRefDPA.prototype.validar = function(){
	var error = false;

	error = this.claveContable.validar();
	
	//Valida la longitud
	if(!error){
		if(this.valor.length>=this.longitudMin && this.valor.length==this.longitudMax)
			;
		else{
			error=true;
			alert(this.descripcion + "\nNo puede ser nulo y debe contener 9 carateres.");
		}
	}
	//Valida caracteres todos num�ricos
	for(var ind=0;ind<this.valor.length && !error;ind++){
		car = this.valor.charAt(ind);
		if(!this.esNumerico(car)){
			error=true;
			alert(this.descripcion + "\nLos caracteres permitidos deben ser de tipo num\u00e9rico.");
			break;
		}
	}
	
	//Valida tercera posicion
	if(!error && (this.valor.charAt(2)!='4' && this.valor.charAt(2)!='6' && this.valor.charAt(2)!='7')){
		error=true;
		alert(this.descripcion + "\nEl d\u00edgito de la tercera posici\u00f3n s\u00f3lo puede ser 4, 6 \u00f3 7");
	}

	
	if(!error){
		var dv = this.digitoVerificador();
		if(dv !== this.valor.substring(8, 9)){
			alert(this.descripcion + "\nVerifique la clave de referencia del DPA o la dependencia seleccionada.");
			error = true;
		}
	}
	
	this.errorValidacion = error;
	return error;
};
ClaveRefDPA.prototype.digitoVerificador = function(){
	var error = false;
	var res = null;
	
	
	if(this.valor!=null && this.valor.length==9 && !error)
	{			
		var numeroControl = this.valor.substring(0,8);

		var ponderacion = 0;

		for(var cont1=1; cont1<=numeroControl.length; cont1++)
		{
			ponderacion += (numeroControl.substring(cont1-1,cont1))*(9-cont1);
		}

		var residuo = ponderacion % 11;

		if(residuo==0 || residuo==1)
		{
			res =  "" + residuo;
		}
		else
		{
			var codigo = 48 + (11-residuo);

			if(codigo>=48 && codigo<=57)
			{
				if(codigo==48)
				{
					res = "0";
				}

				else if(codigo==49)
				{
					res =  "1";
				}

				else if(codigo==50)
				{
					res =  "2";
				}

				else if(codigo==51)
				{
					res =  "3";
				}

				else if(codigo==52)
				{
					res =  "4";
				}

				else if(codigo==53)
				{
					res =  "5";
				}

				else if(codigo==54)
				{
					res =  "6";
				}

				else if(codigo==55)
				{
					res =  "7";
				}

				else if(codigo==56)
				{
					res =  "8";
				}

				else if(codigo==57)
				{
					res =  "9";
				}
			}
		}
	}

	return res;
};
ClaveRefDPA.prototype.onKeyUp = function(event){
	var res = true;
	
	var destino = document.getElementById(this.campoDestino);
	if(destino.value.length >= 2){
		switch(destino.value.charAt(2)){
		case '6':
		case '7':
			if(this.caracter3!=='6' && this.caracter3!=='7'){
				this.caracter3 = destino.value.charAt(2);
			}
			break;
		case '4':
			if(this.caracter3!=='4'){
				this.caracter3 = destino.value.charAt(2);
			}
			break;
		default:
			this.caracter3 = destino.value.charAt(2);
			break;
		}
	}else{
		this.caracter3 = '';
	}
	
	
	return res;
};
//Fin ClaveRefDPA//Clase DatosIdentificacion
//Elaborado por David Aguilera Cuevas jul2016
//
var DatosIdentificacion = function(dpa){
	this.dpa = dpa;
	this.errorValidacion = false;
	this.campos = new Array();
	
	this.rfc = new RFC('', 'X(13)', 'textfieldRFC');
	if(dpa.tarjetaTributariaOK)
		this.rfc.modo=MODO_TARJETA_TRIBUTARIA;
	this.campos.push(this.rfc);
	this.copiaRFC = new RFC('', 'X(13)', 'textfieldConfirmarRFC');
	this.campos.push(this.copiaRFC);
	this.dependencia = new Dependencia('', '99', 'comboboxDependencia');
	this.campos.push(this.dependencia);
	this.curp = new CURP('','','textfieldCURP');
	this.campos.push(this.curp);
	this.paterno = new Apellido('', '', 'textfieldPaterno');
	this.campos.push(this.paterno);
	this.materno = new Apellido('','', 'textfieldMaterno');
	this.campos.push(this.materno);
	this.nombre = new NOMBRE('', '', 'textfieldNombre');
	this.campos.push(this.nombre);
	this.razon = new RAZON('', '', 'textfieldRazonSocial');
	this.campos.push(this.razon);
	this.nombreTarjetaTrib = new Apellido('', '', 'textfieldNombreTarjetaTributaria');
	this.campos.push(this.nombreTarjetaTrib);
	this.nombreTarjetaTrib.claveInformatica = '10007';
	this.nombreTarjetaTrib.descripcion = 'Nombre en Tarjeta Tributaria';

};
DatosIdentificacion.prototype.constructor = DatosIdentificacion;
DatosIdentificacion.prototype.iniciarConRFC = function (){
	this.rfc.recuperarDeDestino();
	this.errorValidacion = this.rfc.validar();
	this.establecerObligarotorios();
};

DatosIdentificacion.prototype.establecerObligarotorios = function(){
	if(this.rfc.persona===PERSONA_FISICA){
		this.paterno.obligatorio=true;
		this.materno.obligatorio=false;
		this.nombre.obligatorio=true;
		this.razon.obligatorio=false;
		this.nombreTarjetaTrib.obligatorio = false;
	}else{
		this.paterno.obligatorio=false;
		this.materno.obligatorio=false;
		this.nombre.obligatorio=false;
		this.razon.obligatorio=true;
		this.nombreTarjetaTrib.obligatorio = false;
	}
};

DatosIdentificacion.prototype.iniciarSinRFC = function (){
	
};
DatosIdentificacion.prototype.crearObjetos = function(){

};

DatosIdentificacion.prototype.validar = function(){
	var res = false;
	res = this.errorValidacion;
	return res;
};

DatosIdentificacion.prototype.validarLimpioCampo = function(nombreCampo){
	var res = false;
	
	for(var ind=0; ind<this.campos.length;ind++){
		var campo = this.campos[ind];
		if(nombreCampo === campo.campoDestino){
			var valorEnPantalla = document.getElementById(campo.campoDestino).value;
			if(valorEnPantalla.length==0){
				campo.ponerValor(valorEnPantalla);
				res = true;
			}
			break;
		}
	}
	return res;
};

DatosIdentificacion.prototype.onKeyDown = function(event, campo){
	var res = false;
	
	for(var ind=0;ind < this.campos.length;ind++){
		if(campo === this.campos[ind].campoDestino){
			res = this.campos[ind].onKeyDown(event);
			break;
		}
	}
	return res;
};

DatosIdentificacion.prototype.onKeyyPress = function(event, campo){
	var res = false;
	
	for(var ind=0;ind < this.campos.length;ind++){
		if(campo === this.campos[ind].campoDestino){
			res = this.campos[ind].onKeyyPress(event);
			break;
		}
	}
	return res;
};

DatosIdentificacion.prototype.onChange = function(event, campo){
	var res = false;
	
	for(var ind=0;ind < this.campos.length;ind++){
		if(campo === this.campos[ind].campoDestino){
			res = this.campos[ind].onChange(event);
			break;
		}
	}
	return res;
};

//Clase DPA
//Elaborado por David Aguilera Cuevas jul2016
//

var DetalleConcepto = function(dpa){
	this.dpa = dpa;

	this.errorValidacion = false;
	this.campos = new Array();
	this.noAplicaPeriodo = new NoAplicaPeriodo('unchecked', '', 'checkboxNoAplicaPeriodo');
	this.noAplicaPeriodo.ponerCampo('unchecked');
	this.noAplicaPeriodo.OnClick();
	this.campos.push(this.noAplicaPeriodo);
	this.claveRefDPA = new ClaveRefDPA('','999999999','textfieldClaveReferenciaDPA');
	this.claveRefDPA.contenedor = this;
	this.campos.push(this.claveRefDPA);
	this.cadenaDependencia = new CadenaDependencia('','','textfieldCadenaDependencia');
	this.campos.push(this.cadenaDependencia);
	this.ejercicio = new Ejercicio('', '', "comboboxEjercicio");
	this.ejercicio.contenedor = this;
	this.campos.push(this.ejercicio);
	this.noAplicaPeriodo.ponerEjercicio(this.ejercicio);
	this.periodo = new Periodo('', '', 'comboboxPeriodo');
	this.periodo.contenedor = this;
	this.campos.push(this.periodo);
	this.noAplicaPeriodo.ponerPeriodo(this.periodo);
	this.ejercicio.periodo = this.periodo;
	
	this.saldoFavorDPA = new Numerico('', '99,999,999,999,999', 'textfieldSaldoFavor');
	this.saldoFavorDPA.claveInformatica = '14703';
	this.saldoFavorDPA.descripcion = 'Saldo a Favor';
	this.saldoFavorDPA.ponerValor('');
	this.campos.push(this.saldoFavorDPA);
	this.importeDPA = new Numerico('', '99,999,999,999,999', 'textfieldACargo');
	this.importeDPA.claveInformatica = '14704';
	this.importeDPA.descripcion = 'Importe';
	this.importeDPA.ponerValor('');
	this.campos.push(this.importeDPA);
	this.parteActualDPA = new Numerico('', '99,999,999,999,999', 'textfieldParteActualizada');
	this.parteActualDPA.claveInformatica = '14705';
	this.parteActualDPA.descripcion = 'Parte Actualizada';
	this.parteActualDPA.ponerValor('');
	this.campos.push(this.parteActualDPA);
	this.recargosDPA = new Numerico('', '99,999,999,999,999', 'textfieldRecargos');
	this.recargosDPA.claveInformatica = '14706';
	this.recargosDPA.descripcion = 'Recargos';
	this.recargosDPA.ponerValor('');
	this.campos.push(this.recargosDPA);
	this.multaDPA = new Numerico('', '99,999,999,999,999', 'textfieldMultaCorrecion');
	this.multaDPA.claveInformatica = '14707';
	this.multaDPA.descripcion = 'Multa por Correcci\u00f3n';
	this.multaDPA.ponerValor('');
	this.campos.push(this.multaDPA);
	this.compensacionesDPA = new Numerico('', '99,999,999,999,999', 'textfieldCompensaciones');
	this.compensacionesDPA.claveInformatica = '14714';
	this.compensacionesDPA.descripcion = 'Compensaciones';
	this.compensacionesDPA.ponerValor('');
	this.campos.push(this.compensacionesDPA);
	this.cantPagarDPA = new Numerico('', '99,999,999,999,999', 'textfieldCantidadPagar');
	this.cantPagarDPA.claveInformatica = '14708';
	this.cantPagarDPA.descripcion = 'Cantidad a pagar';
	this.cantPagarDPA.ponerCampo('0');
	this.campos.push(this.cantPagarDPA);
	this.totalEfecPagado = new Numerico('', '99,999,999,999,999', '');
	this.totalEfecPagado.claveInformatica = '';
	this.totalEfecPagado.descripcion = 'Total efectivamente pagado';
	this.campos.push(this.totalEfecPagado);

	//Campos para IVA
	this.importeIVA = new Numerico('', '99,999,999,999,999', 'textfieldIvaACargo');
	this.importeIVA.claveInformatica = '12004';
	this.importeIVA.descripcion = 'Importe';
	this.importeIVA.ponerValor('');
	this.campos.push(this.importeIVA);
	this.parteActualIVA = new Numerico('', '99,999,999,999,999', 'textfieldIvaParteActualizada');
	this.parteActualIVA.claveInformatica = '12005';
	this.parteActualIVA.descripcion = 'Parte Actualizada';
	this.parteActualIVA.ponerValor('');
	this.campos.push(this.parteActualIVA);
	this.recargosIVA = new Numerico('', '99,999,999,999,999', 'textfieldIvaRecargos');
	this.recargosIVA.claveInformatica = '12006';
	this.recargosIVA.descripcion = 'Recargos';
	this.recargosIVA.ponerValor('');
	this.campos.push(this.recargosIVA);
	this.multaIVA = new Numerico('', '99,999,999,999,999', 'textfieldIvaMultaCorrecion');
	this.multaIVA.claveInformatica = '12007';
	this.multaIVA.descripcion = 'Multa por Correcci\u00f3n';
	this.multaIVA.ponerValor('');
	this.campos.push(this.multaIVA);
	this.cantPagarIVA = new Numerico('', '99,999,999,999,999', 'textfieldTotalIva');
	this.cantPagarIVA.claveInformatica = '12008';
	this.cantPagarIVA.descripcion = 'Cantidad a pagar';
	this.cantPagarIVA.ponerCampo('0');
	this.campos.push(this.cantPagarIVA);
	
	
};
DetalleConcepto.prototype.limpiarImportes = function(){
	if(typeof this.saldoFavorDPA !== 'undefined')
		this.saldoFavorDPA.ponerCampo('');
	if(typeof this.importeDPA !== 'undefined')
		this.importeDPA.ponerCampo('');
	if(typeof this.compensacionesDPA !== 'undefined')
		this.compensacionesDPA.ponerCampo('');
	if(typeof this.parteActualDPA !== 'undefined')
		this.parteActualDPA.ponerCampo('');
	if(typeof this.recargosDPA !== 'undefined')
		this.recargosDPA.ponerCampo('');
	if(typeof this.multaDPA !== 'undefined')
		this.multaDPA.ponerCampo('');
	if(typeof this.cantPagarDPA !== 'undefined')
		this.cantPagarDPA.ponerCampo('0');
};
DetalleConcepto.prototype.limpiarImportesIVA = function(){
	if(typeof this.importeIVA !== 'undefined')
		this.importeIVA.ponerCampo('');
	if(typeof this.parteActualIVA !== 'undefined')
		this.parteActualIVA.ponerCampo('');
	if(typeof this.recargosIVA !== 'undefined')
		this.recargosIVA.ponerCampo('');
	if(typeof this.multaIVA !== 'undefined')
		this.multaIVA.ponerCampo('');
	if(typeof this.cantPagarIVA !== 'undefined')
		this.cantPagarIVA.ponerCampo('0');
};
DetalleConcepto.prototype.habilitarImportesDPA = function(digito){
	
	if(typeof this.importeDPA === 'undefined' || typeof this.parteActualDPA === 'undefined'
		|| typeof this.recargosDPA === 'undefined' || typeof this.multaDPA === 'undefined')
			return;
	
	switch(digito){
	case '0':		//Configuraci�n 0 deshabilita todos los campos
		this.importeDPA.habilitar(false);
		this.parteActualDPA.habilitar(false);
		this.recargosDPA.habilitar(false);
		this.multaDPA.habilitar(false);
		break;
	case '1':		//Configuraci�n 1 habilita todos los campos
		this.importeDPA.habilitar(true);
		this.parteActualDPA.habilitar(true);
		this.recargosDPA.habilitar(true);
		this.multaDPA.habilitar(true);
		break;
	default:		//Configuraci�n default deshabilita todos los campos
		this.importeDPA.habilitar(false);
		this.parteActualDPA.habilitar(false);
		this.recargosDPA.habilitar(false);
		this.multaDPA.habilitar(false);
		break;
	}
};

DetalleConcepto.prototype.habilitarImportesIVA = function(digito){

	if(typeof this.compensacionesDPA === 'undefined' || typeof this.importeIVA === 'undefined'
		|| typeof this.parteActualIVA === 'undefined' || typeof this.recargosIVA === 'undefined'
		|| typeof this.multaIVA === 'undefined' )
			return;
	
	switch(digito){
	case '4':
		this.compensacionesDPA.habilitar(true);
		this.importeIVA.habilitar(false);
		this.parteActualIVA.habilitar(false);
		this.recargosIVA.habilitar(false);
		this.multaIVA.habilitar(false);
		break;
	case '6':
	case '7':
		this.compensacionesDPA.habilitar(false);
		this.importeIVA.habilitar(true);
		this.parteActualIVA.habilitar(true);
		this.recargosIVA.habilitar(true);
		this.multaIVA.habilitar(true);
		break;
	default:
		this.compensacionesDPA.habilitar(false);
		this.importeIVA.habilitar(false);
		this.parteActualIVA.habilitar(false);
		this.recargosIVA.habilitar(false);
		this.multaIVA.habilitar(false);
		break;
	}
	this.compensacionesDPA.ponerCampo('');
	this.importeIVA.ponerCampo('');
	this.parteActualIVA.ponerCampo('');
	this.recargosIVA.ponerCampo('');
	this.multaIVA.ponerCampo('');
	this.cantPagarIVA.ponerCampo('0');
	
};
DetalleConcepto.prototype.campoGanoFoco = function(campo){
	
	for(var ind=0;ind < this.campos.length;ind++){
		if(campo === this.campos[ind].campoDestino){
			this.campos[ind].ponerEnDestinoSinMascara();
			break;
		}
	}
};
DetalleConcepto.prototype.campoPerdioFoco = function(campo){
	
	for(var ind=0;ind < this.campos.length;ind++){
		if(campo === this.campos[ind].campoDestino){
			this.campos[ind].recuperarDeDestino();
			this.campos[ind].ponerEnDestino();
			break;
		}
	}
};
DetalleConcepto.prototype.onKeyDown = function(event, campo){
	var res = false;
	
	for(var ind=0;ind < this.campos.length;ind++){
		if(campo === this.campos[ind].campoDestino){
			res = this.campos[ind].onKeyDown(event);
			break;
		}
	}
	return res;
};
DetalleConcepto.prototype.onKeyyPress = function(event, campo){
	var res = false;
	
	for(var ind=0;ind < this.campos.length;ind++){
		if(campo === this.campos[ind].campoDestino){
			res = this.campos[ind].onKeyyPress(event);
			break;
		}
	}
	return res;
};

DetalleConcepto.prototype.onKeyUp = function(event, campo){
	var res = false;
	
	for(var ind=0;ind < this.campos.length;ind++){
		if(campo === this.campos[ind].campoDestino){
			res = this.campos[ind].onKeyUp(event);
			break;
		}
	}
	return res;
};

DetalleConcepto.prototype.onChange = function(event, campo){
	var res = false;
	
	for(var ind=0;ind < this.campos.length;ind++){
		if(campo === this.campos[ind].campoDestino){
			res = this.campos[ind].onChange(event);
			break;
		}
	}
	return res;
};

DetalleConcepto.prototype.validar = function(){
	var res = false;
	
	this.cadenaDependencia.recuperarDeDestino();	
	for(var ind=0;ind < this.campos.length;ind++){
		res = this.campos[ind].validar();
		if(res == true)
			break;
	}
	
	this.errorValidacion = res;
	return res;
};

DetalleConcepto.prototype.validarLimpioCampo = function(nombreCampo){
	var res = false;	
	for(var ind=0; ind<this.campos.length;ind++){
		var campo = this.campos[ind];
		if(nombreCampo === campo.campoDestino){
			var valorEnPantalla = document.getElementById(campo.campoDestino).value;
			if(valorEnPantalla.length==0){
				campo.ponerValor(valorEnPantalla);
				res = true;
				//Cuando es un importe tiene que calcular los totales de nuevo
				this.dpa.ponerOpcionPago(nombreCampo);
				this.dpa.setCantidadesCalculadas();
			}
			break;
		}
	}
	return res;
};

DetalleConcepto.prototype.reiniciarImportes = function(){
	this.limpiarImportes();
	this.limpiarImportesIVA();
	this.habilitarImportesDPA('1');
	this.habilitarImportesIVA('0');
	this.cadenaDependencia.ponerCampo('');
};
//Clase DPA
//Elaborado por David Aguilera Cuevas jul2016
//

//Constructor
var DPA = function(){
	
	this.sinRFC = false;
	this.opcionPago = 0;
	this.totalContribuciones = 0;
	this.totalContribucionesDPA = 0;
	this.cantidadPagar = 0;
	this.totalContribucionesIVA = 0;
	this.cantidadPagarIVA = 0;
	this.tarjetaTributariaOK = (PARAM_TARJETA_TRIBUTARIA === "V"? true:false);

	this.datosIdentificacion = new DatosIdentificacion(this);
	this.detalleConcepto = new DetalleConcepto(this);
	this.detalles = Array();
	this.detalles.push(this.detalleConcepto);
	this.valoresDPA = [];
	this.valoresDPA[descripConcepto.length - 1] = 0;
	this.valoresIVA = [];
	this.valoresIVA[descripConcepto.length - 1] = 0;
	this.validarComp = false;
	this.consecutivo = 0;
	this.lineaInicial = 0;
	this.lineaFinal = 0;
	this.errorValidacion = false;
};

DPA.prototype.constructor = DPA;

DPA.prototype.continuar = function(sinRFC){
	
	this.sinRFC = sinRFC;
	if(!this.sinRFC){
		this.datosIdentificacion.iniciarConRFC();
		if(!this.datosIdentificacion.errorValidacion){
			//Los rfcs deben ser iguales
			this.datosIdentificacion.copiaRFC.recuperarDeDestino();
			if(this.datosIdentificacion.rfc.valor!==this.datosIdentificacion.copiaRFC.valor)
				alert("Error.\nDebe confirmar correctamente el RFC.");
			else{
				this.inicializarIU();
				this.crearObjetos();
				if(this.datosIdentificacion.rfc.persona===PERSONA_FISICA)
					this.datosIdentificacion.curp.ponerFoco();
				else
					this.datosIdentificacion.razon.ponerFoco();
			}
		}
	}else{
		this.datosIdentificacion.iniciarSinRFC();
		this.inicializarIU();
		this.crearObjetos();
		this.datosIdentificacion.curp.ponerFoco();
	}
};

DPA.prototype.validar = function(){
	
	try{
		this.errorValidacion = this.datosIdentificacion.validar();
		if(this.errorValidacion == false)
			this.errorValidacion = this.detalleConcepto.validar();
	}catch(excepcion){
		this.errorValidacion = true;
		var msgerror = excepcion.toString();
		if(msgerror.length > 0)
			alert (msgerror);
	}
};

DPA.prototype.ponerResultadoDPA = function(){

	if ((this.detalleConcepto.importeDPA.habilitado())
			&& (this.detalleConcepto.importeDPA.valor.length == 0)) {
		throw "Importe\nDebe ser mayor o igual a cero";
	}

	if ((this.detalleConcepto.compensacionesDPA.habilitado()) && (this.detalleConcepto.compensacionesDPA.valor.length > 0)) {
		if (this.detalleConcepto.compensacionesDPA.valorNumerico() > 0) {
			this.validarComp = true;
			var mandarMensaje = this.setCantidadesCalculadas();
			if(mandarMensaje){
				if (this.totalContribucionesDPA < this.detalleConcepto.compensacionesDPA.valorNumerico()) {
					this.validarComp = false;
					throw "Compensaciones\nSi se captura debe ser menor o igual al total de contribuciones.";
				}
			}
			this.validarComp = false;
		} else {
			throw "Compensaciones\nSi se captura debe ser mayor a cero.";
		}
	}
	
	if (this.cantidadPagar < 0) {
		throw "Cantidad a Pagar\nDebe ser mayor o igual a cero, se calcula, es el resultado de la resta de Total de Contribuciones menos Compensaciones (C11 - C13).";
	}

	if (claveInfDPA[32] != null) {
		this.datosIdentificacion.dependencia.recuperarDeDestino();

		if (this.datosIdentificacion.dependencia.valor !==  this.detalleConcepto.claveRefDPA.valor.substring(0, 2)) {
			throw "Clave de Referencia del DPA\nVerifique la clave de referencia del DPA o la dependencia seleccionada.";
		}
	}
	if (claveInfDPA[33] != null) {
		this.detalleConcepto.cadenaDependencia.recuperarDeDestino();
	}

	var periodicidad = this.detalleConcepto.noAplicaPeriodo.periodoSeleccionado();
	if (this.detalleConcepto.noAplicaPeriodo.valor !== 'checked') {
		if(periodicidad !== null)
			this.valoresDPA[2] = periodicidad.periodo.valor;
		else
			this.valoresDPA[2] = 'XXX';
		this.valoresDPA[3] = periodicidad.ejercicio.valor;
	} else if ((this.detalleConcepto.noAplicaPeriodo.valor === 'checked')
			&& (this.valoresDPA[1] === claveInfIVA[1])) {
		if(periodicidad !== null)
			this.valoresDPA[2] = periodicidad.periodo.valor;
		else
			this.valoresDPA[2] = 'XXX';
		this.valoresDPA[3] = periodicidad.ejercicio.valor;
	}

	if ((this.detalleConcepto.parteActualDPA.valor.length > 0)
			&& (this.detalleConcepto.parteActualDPA.valorNumerico() <= 0)) {
		throw "Parte actualizada DPA\nSi se captura debe ser mayor a cero.";
	}

	if ((this.detalleConcepto.parteActualIVA.valor.length > 0)
			&& (this.detalleConcepto.parteActualIVA.valorNumerico() <= 0)) {
		throw "Parte actualizada IVA\nSi se captura debe ser mayor a cero.";
	}

	if ((this.detalleConcepto.recargosDPA.valor.length > 0)
			&& (this.detalleConcepto.recargosDPA.valorNumerico() <= 0)) {
		throw "Recargos DPA\nSi se captura debe ser mayor a cero.";
	}

	if ((this.detalleConcepto.recargosIVA.valor.length > 0)
			&& (this.detalleConcepto.recargosIVA.valorNumerico() <= 0)) {
		throw "Recargos IVA\nSi se captura debe ser mayor a cero.";
	}

	if ((this.detalleConcepto.multaDPA.valor.length > 0)
			&& (this.detalleConcepto.multaDPA.valorNumerico() <= 0)) {
		throw "Multa por correci\u00f3n DPA\nSi se captura debe ser mayor a cero.";
	}

	if ((this.detalleConcepto.multaIVA.valor.length > 0)
			&& (this.detalleConcepto.multaIVA.valorNumerico() <= 0)) {
		throw "Multa por correci\u00f3n IVA\nSi se captura debe ser mayor a cero.";
	}

	if (this.detalleConcepto.importeDPA.valor.length > 0) {
		this.valoresDPA[6] = this.detalleConcepto.importeDPA.valor;
	}

	if (this.detalleConcepto.parteActualDPA.valor.length > 0) {
		this.valoresDPA[7] = this.detalleConcepto.parteActualDPA.valor;
	}

	if (this.detalleConcepto.recargosDPA.valor.length > 0) {
		this.valoresDPA[8] = this.detalleConcepto.recargosDPA.valor;
	}

	if (this.detalleConcepto.multaDPA.valor.length > 0) {
		this.valoresDPA[9] = this.detalleConcepto.multaDPA.valor;
	}
	this.valoresDPA[10] = ("" + this.totalContribucionesDPA);
	this.valoresDPA[26] = ("" + this.cantidadPagar);


	if ((this.detalleConcepto.compensacionesDPA.habilitado()) && (this.detalleConcepto.compensacionesDPA.valor.length > 0)) {
		if (this.detalleConcepto.compensacionesDPA.valorNumerico() > 0) {
			this.valoresDPA[12] = this.detalleConcepto.compensacionesDPA.valor;
		} 
	}
	
	if (this.detalleConcepto.saldoFavorDPA.habilitado()) {
		if (this.detalleConcepto.saldoFavorDPA.valor.length > 0) {
			if (this.detalleConcepto.saldoFavorDPA.valorNumerico() <= 0) {
				throw "Saldo a favor\nDebe ser mayor a cero.";
			}
			this.valoresDPA[25] = this.detalleConcepto.saldoFavorDPA.valor;
		} else {
			throw "Saldo a favor\nDebe ser mayor a cero.";
		}
	}

	if (claveInfDPA[32] != null) {
		this.valoresDPA[32] = ("" + this.detalleConcepto.claveRefDPA.valor);
	}

	if (claveInfDPA[33] != null) {
		this.valoresDPA[33] = ("" + this.detalleConcepto.cadenaDependencia.valor);
	}

	if (claveInfDPA[28]==="47") {
		this.consecutivo = this.consecutivo + 1;
	}

	this.valoresDPA[34] = ("" + this.consecutivo);

	var listDetalle = document.getElementById("listDetalle");

	this.lineaInicial = listDetalle.length;

	this.addListDetalleValues(claveInfDPA, this.valoresDPA);

	this.lineaFinal = listDetalle.length - 1;
	
};

DPA.prototype.ponerResultadoIVA = function(){
	//clave_referencia_dpa.reset();
	//cadena_dependencia.reset();

	//setPeriodoSeleccionado();
	
	var periodicidad = this.detalleConcepto.noAplicaPeriodo.periodoSeleccionado();
	if(periodicidad !== null){
		this.valoresIVA[2] = periodicidad.periodo.valor;
		this.valoresIVA[3] = periodicidad.ejercicio.valor;
	}
	else{
		this.valoresIVA[2] = 'XXX';
		this.valoresIVA[3] = 'XXX';
	}

	if (this.detalleConcepto.parteActualIVA.valor.length > 0) {
		this.valoresIVA[7] = this.detalleConcepto.parteActualIVA.valor;
	}
	if (this.detalleConcepto.recargosIVA.valor.length > 0) {
		this.valoresIVA[8] = this.detalleConcepto.recargosIVA.valor;
	}

	if (this.detalleConcepto.multaIVA.valor.length > 0) {
		this.valoresIVA[9] = this.detalleConcepto.multaIVA.valor;
	}
	if (this.detalleConcepto.importeIVA.valor.length > 0) {
		this.valoresIVA[6] = this.detalleConcepto.importeIVA.valor;
	} else {
		this.detalleConcepto.importeIVA.ponerCampo('0');
		this.valoresIVA[6] = this.detalleConcepto.importeIVA.valor;
	}
	this.valoresIVA[10] = ("" + this.totalContribucionesIVA);
	this.valoresIVA[26] = ("" + this.cantidadPagarIVA);
	
	if ((this.detalleConcepto.compensacionesDPA.habilitado()) && (this.detalleConcepto.compensacionesDPA.valor.length > 0)) {
		if (this.detalleConcepto.compensacionesDPA.valorNumerico() > 0) {
			this.valoresIVA[12] = this.detalleConcepto.compensacionesDPA.valor;
		} 
	}
	
	if (this.detalleConcepto.saldoFavorDPA.habilitado()) {
		if (this.detalleConcepto.saldoFavorDPA.valor.length > 0) {
			if (this.detalleConcepto.saldoFavorDPA.valorNumerico() <= 0) {
				throw "Saldo a favor\nDebe ser mayor a cero.";
			}
			this.valoresIVA[25] = this.detalleConcepto.saldoFavorDPA.valor;
		} else {
			throw "Saldo a favor\nDebe ser mayor a cero.";
		}
	}

	if (claveInfIVA[32] != null) {
		this.valoresIVA[32] = ("" + this.detalleConcepto.claveRefDPA.valor);
	}

	if (claveInfIVA[33] != null) {
		this.valoresIVA[33] = ("" + this.detalleConcepto.cadenaDependencia.valor);
	}

	if (claveInfIVA[28]==="47") {
		this.consecutivo = this.consecutivo + 1;
	}

	this.valoresIVA[34] = ("" + this.consecutivo);

	this.addListDetalleValues(claveInfIVA, this.valoresIVA);

	var listDetalle = document.getElementById("listDetalle");

	this.lineaFinal = listDetalle.length - 1;
	
};

function nuevoDetalle(valor){
	var opcion = document.createElement('option');
    opcion.text = valor;
    opcion.value = valor;

    return opcion;
}

DPA.prototype.addListDetalleValues = function(clavesInformaticas, valores){
	var listDetalle = document.getElementById("listDetalle");
	var numero = new Numerico('','99,999,999,999,999','');
	
	listDetalle.add(nuevoDetalle(clavesInformaticas[0]));
	
	listDetalle.add(nuevoDetalle("                              "));

	if (valores[2] != null) {
		if(clavesInformaticas[2]!=='12002'){
			listDetalle.add(nuevoDetalle("   Per\u00edodo: "
					+ this.detalleConcepto.periodo.recuperarNombre()
					+ " del " + valores[3]));
		}else{
			var parts = PARAM_FECHA_ACTUAL.split("/");
			listDetalle.add(nuevoDetalle("   Per\u00edodo: "
					+ this.detalleConcepto.periodo.meses[parseInt(parts[1], 10) - 1]
					+ " del " + parseInt(parts[2]),10));
		}
	} else {
		listDetalle.add(nuevoDetalle("   [No aplica per\u00edodo]"));
	}

	listDetalle.add(nuevoDetalle("                              "));

	if (valores[25] != null) {
		numero.ponerValor(valores[25]);
		listDetalle.add(nuevoDetalle("   Saldo a favor: "
				+ numero.valorConMascara));
	}
	
	if (valores[6] != null) {
		numero.ponerValor(valores[6]);
		listDetalle.add(nuevoDetalle("   Importe: "
				+ numero.valorConMascara));
	}

	listDetalle.add(nuevoDetalle("                              "));

	for (var cont1 = 7; cont1 <= 9; cont1++) {
		if (valores[cont1] == null)
			continue;
		numero.ponerValor(valores[cont1]);
		listDetalle.add(nuevoDetalle("   "
				+ descripConcepto[cont1]
				+ ": " + numero.valorConMascara));
	}

	if (valores[12] != null) {
		numero.ponerValor(valores[12]);
		listDetalle.add(nuevoDetalle("   Compensaciones: "
				+ numero.valorConMascara));
	}

	listDetalle.add(nuevoDetalle("                              "));

	if (valores[26] != null) {
		numero.ponerValor(valores[26]);
		listDetalle.add(nuevoDetalle("   Cantidad a pagar: "
				+ numero.valorConMascara));
	}

	listDetalle.add(nuevoDetalle("                              "));

	if (valores[32] != null) {
		listDetalle.add(nuevoDetalle("   Clave de referencia del DPA: "
				+ valores[32]));
	}

	if (valores[33] != null) {
		listDetalle.add(nuevoDetalle("   Cadena de la dependencia: "
				+ valores[33]));
	}

	listDetalle
			.add(nuevoDetalle("--------------------------------------------------"));

	if (listDetalle.length > 0) {
		listDetalle.disabled = false;
		//buttonBorrarConcepto.setEnabled(true);
	}

	
	/*
	String[] clavesInformaticas = (String[]) keysImpuestos.elementAt(keysImpuestos
			.size() - 1);

	if (ivaHabilitado) {
		comboboxTipoImpuesto.removeItemListener(this);
		comboboxTipoImpuesto.removeAll();
		comboboxTipoImpuesto.addItem(ToolkitDerechos.TAX_TYPE_ITEMS[0]);
		comboboxTipoImpuesto.addItemListener(this);
	} else {
		comboboxTipoImpuesto.removeItemListener(this);
		comboboxTipoImpuesto.removeAll();
		comboboxTipoImpuesto.addItem(ToolkitDerechos.TAX_TYPE_ITEMS[1]);
		comboboxTipoImpuesto.addItemListener(this);
	}

	comboboxTipoImpuesto.select(0);

	addComboBoxImpuestoValues();
	setClavesImpuestoSeleccionado();
	addRadioButtonValues();
	addComboBoxEjercicioValues();
	addComboBoxPeriodoValues();
	addCargosAplicacionesValues();
	addOtherValues();
	setCantidadesCalculadas();
	limpiarValores();
	if (auxIva)
		limpiarValoresIVA();
	*/
};

DPA.prototype.conValoresIVA = function(){
	var res = false;
	
	if ((this.detalleConcepto.parteActualIVA.valor.length > 0) ||
		(this.detalleConcepto.recargosIVA.valor.length > 0) ||
		(this.detalleConcepto.multaIVA.valor.length > 0)  ||
		(this.detalleConcepto.importeIVA.valor.length > 0)) {
		res = true;
	}
	
	return res;
};

function getHtmlVer(){
	  var CName  = navigator.appCodeName;
	  var UAgent = navigator.userAgent;
	  var HtmlVer= 0.0;
	  // Remove start of string in UAgent upto CName or end of string if not found.
	  UAgent = UAgent.substring((UAgent+CName).toLowerCase().indexOf(CName.toLowerCase()));
	  // Remove CName from start of string. (Eg. '/5.0 (Windows; U...)
	  UAgent = UAgent.substring(CName.length);
	  // Remove any spaves or '/' from start of string.
	  while(UAgent.substring(0,1)==" " || UAgent.substring(0,1)=="/"){
	    UAgent = UAgent.substring(1);
	  }
	  // Remove the end of the string from first characrer that is not a number or point etc.
	  var pointer = 0;
	  while("0123456789.+-".indexOf((UAgent+"?").substring(pointer,pointer+1))>=0){
	    pointer = pointer+1;
	  }
	  UAgent = UAgent.substring(0,pointer);
	 
	  if(!isNaN(UAgent)){
	    if(UAgent>0){
	    HtmlVer=UAgent;
	    }
	  }
	  return HtmlVer;
	};


	//Habilita los campos de captura en funci�n del tipo de RFC
DPA.prototype.inicializarIU = function(){
	this.conTrajetaTributaria = false;
	
	document.getElementById("textfieldRFC").disabled = true;
	document.getElementById("textfieldRFC").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldConfirmarRFC").disabled = true;
	document.getElementById("textfieldConfirmarRFC").style.backgroundColor=ColorDisabled;
	document.getElementById("buttonContinuar").disabled = true;
	document.getElementById("buttonContinuarSinRFC").disabled = true;

	document.getElementById("checkboxNoAplicaPeriodo").disabled = false;
	document.getElementById("checkboxNoAplicaPeriodo").style.backgroundColor=ColorEnabled;
	document.getElementById("comboboxDependencia").disabled = false;
	document.getElementById("comboboxDependencia").style.backgroundColor=ColorEnabled;
	document.getElementById("textfieldCadenaDependencia").disabled = false;
	document.getElementById("textfieldCadenaDependencia").style.backgroundColor=ColorEnabled;
	document.getElementById("buttonConfirmarDatos").disabled = false;
	document.getElementById("buttonAgregarConcepto").disabled = false;
	document.getElementById("buttonBorrarTodo").disabled = false;
	document.getElementById("textfieldCURP").disabled = false;
	document.getElementById("textfieldCURP").style.backgroundColor=ColorEnabled;
	
	document.getElementById("radiobuttonMensual").disabled = false;
	document.getElementById("radiobuttonMensual").style.backgroundColor=ColorEnabled;
	document.getElementById("radiobuttonBimestral").disabled = false;
	document.getElementById("radiobuttonBimestral").style.backgroundColor=ColorEnabled;
	document.getElementById("radiobuttonTrimestral").disabled = false;
	document.getElementById("radiobuttonTrimestral").style.backgroundColor=ColorEnabled;
	document.getElementById("radiobuttonCuatrimestral").disabled = false;
	document.getElementById("radiobuttonCuatrimestral").style.backgroundColor=ColorEnabled;
	document.getElementById("radiobuttonSemestral").disabled = false;
	document.getElementById("radiobuttonSemestral").style.backgroundColor=ColorEnabled;
	document.getElementById("radiobuttonDelEjercicio").disabled = false;
	document.getElementById("radiobuttonDelEjercicio").style.backgroundColor=ColorEnabled;
	document.getElementById("comboboxEjercicio").disabled = false;
	document.getElementById("comboboxEjercicio").style.backgroundColor=ColorEnabled;
	document.getElementById("textfieldClaveReferenciaDPA").disabled = false;
	document.getElementById("textfieldClaveReferenciaDPA").style.backgroundColor=ColorEnabled;
	document.getElementById("comboboxPeriodo").disabled = false;
	document.getElementById("comboboxPeriodo").style.backgroundColor=ColorEnabled;
	
	if(!this.conTarjetaTributaria){
		if(this.datosIdentificacion.rfc.persona === PERSONA_FISICA){
			document.getElementById("LabelPaterno").style.display = 'block';
			document.getElementById("textfieldPaterno").style.display = 'block';
			document.getElementById("LabelMaterno").style.display = 'block';
			document.getElementById("textfieldMaterno").style.display = 'block';
			document.getElementById("LabelNombre").style.display = 'block';
			document.getElementById("textfieldNombre").style.display = 'block';
			document.getElementById("LabelDenominacion").style.display='none';
			document.getElementById("textfieldRazonSocial").style.display='none';
			
			document.getElementById("textfieldPaterno").disabled = false;
			document.getElementById("textfieldPaterno").style.backgroundColor=ColorEnabled;
			document.getElementById("textfieldMaterno").disabled = false;
			document.getElementById("textfieldMaterno").style.backgroundColor=ColorEnabled;
			document.getElementById("textfieldNombre").disabled = false;
			document.getElementById("textfieldNombre").style.backgroundColor=ColorEnabled;
			document.getElementById("textfieldRazonSocial").disabled=true;
			document.getElementById("textfieldRazonSocial").style.backgroundColor=ColorEnabled;
		}else if(this.datosIdentificacion.rfc.persona === PERSONA_MORAL){
			document.getElementById("textfieldCURP").disabled = true;
			document.getElementById("textfieldCURP").style.backgroundColor=ColorDisabled;
			document.getElementById("LabelCURP").style.display = 'none';
			document.getElementById("textfieldCURP").style.display = 'none';
			document.getElementById("LabelPaterno").style.display = 'none';
			document.getElementById("textfieldPaterno").style.display = 'none';
			document.getElementById("LabelMaterno").style.display = 'none';
			document.getElementById("textfieldMaterno").style.display = 'none';
			document.getElementById("LabelNombre").style.display = 'none';
			document.getElementById("textfieldNombre").style.display = 'none';
			document.getElementById("LabelDenominacion").style.display='block';
			document.getElementById("textfieldRazonSocial").style.display='block';
			
			document.getElementById("textfieldRazonSocial").disabled=false;
			document.getElementById("textfieldRazonSocial").style.backgroundColor=ColorEnabled;
			document.getElementById("textfieldPaterno").disabled = true;
			document.getElementById("textfieldPaterno").style.backgroundColor=ColorDisabled;
			document.getElementById("textfieldMaterno").disabled = true;
			document.getElementById("textfieldMaterno").style.backgroundColor=ColorDisabled;
			document.getElementById("textfieldNombre").disabled = true;
			document.getElementById("textfieldNombre").style.backgroundColor=ColorDisabled;
		}
	}else{
		//Habilita textfieldNombreTarjetaTributaria
		document.getElementById("LabelTarjetaTributaria").style.display='block';
		document.getElementById("textfieldNombreTarjetaTributaria").style.display='block';
		document.getElementById("textfieldNombreTarjetaTributaria").disabled = false;
		document.getElementById("textfieldNombreTarjetaTributaria").style.backgroundColor=ColorEnabled;
	}
	
	if(this.sinRFC){
		document.getElementById("textfieldPaterno").disabled = false;
		document.getElementById("textfieldPaterno").style.backgroundColor=ColorEnabled;
		document.getElementById("textfieldMaterno").disabled = false;
		document.getElementById("textfieldMaterno").style.backgroundColor=ColorEnabled;
		document.getElementById("textfieldNombre").disabled = false;
		document.getElementById("textfieldNombre").style.backgroundColor=ColorEnabled;
		document.getElementById("LabelDenominacion").style.display='block';
		document.getElementById("textfieldRazonSocial").style.display='block';
		document.getElementById("textfieldRazonSocial").disabled=false;
		document.getElementById("textfieldRazonSocial").style.backgroundColor=ColorEnabled;
	}
	
	document.getElementById("textfieldACargo").disabled = false;
	document.getElementById("textfieldACargo").style.backgroundColor=ColorEnabled;
	document.getElementById("textfieldParteActualizada").disabled = false;
	document.getElementById("textfieldParteActualizada").style.backgroundColor=ColorEnabled;
	document.getElementById("textfieldRecargos").disabled = false;
	document.getElementById("textfieldRecargos").style.backgroundColor=ColorEnabled;
	document.getElementById("textfieldMultaCorrecion").disabled = false;
	document.getElementById("textfieldMultaCorrecion").style.backgroundColor=ColorEnabled;
	document.getElementById("textfieldCompensaciones").disabled = true;
	document.getElementById("textfieldCompensaciones").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldCantidadPagar").disabled = true;
	document.getElementById("textfieldCantidadPagar").style.backgroundColor=ColorDisabled;

	document.getElementById("textfieldIvaACargo").disabled = true;
	document.getElementById("textfieldIvaACargo").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldIvaParteActualizada").disabled = true;
	document.getElementById("textfieldIvaParteActualizada").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldIvaRecargos").disabled = true;
	document.getElementById("textfieldIvaRecargos").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldIvaMultaCorrecion").disabled = true;
	document.getElementById("textfieldIvaMultaCorrecion").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldTotalIva").disabled = true;
	document.getElementById("textfieldTotalIva").style.backgroundColor=ColorDisabled;
	document.getElementById("listDetalle").disabled = false;
	document.getElementById("listDetalle").style.backgroundColor=ColorDisabled;

	document.getElementById("buttonBorrarConcepto").disabled = true;
	
};


DPA.prototype.crearObjetos = function(){
	this.datosIdentificacion.crearObjetos();
};

DPA.prototype.detalleOnKeyPress = function(event, campo){
	return this.detalleConcepto.onKeyyPress(event, campo);
};

DPA.prototype.OnKeyUp = function(event, campo){

	if ((campo === 'textfieldRFC') && (this.tarjetaTributariaOK==true)) {
		this.OnKeyUpRFCTarjetaTrib(event);
	}
	
};

DPA.prototype.OnKeyUpRFCTarjetaTrib = function(event){
	this.datosIdentificacion.rfc.OnKeyUp(event);
	var unRFC = this.datosIdentificacion.rfc.valor;
	var unNombre = this.datosIdentificacion.rfc.nombreTarjetaTrib;
	if (this.datosIdentificacion.rfc.procesoBandaOCodigo && (unRFC.length != 0) && (unNombre.length == 0)) {
		var copiaRfc = document.getElementById("textfieldConfirmarRFC");
		copiaRfc.value = unRFC;
		
		document.getElementById("LabelPaterno").style.display='none';
		this.datosIdentificacion.paterno.mostrar(false);
		document.getElementById("LabelMaterno").style.display='none';
		this.datosIdentificacion.materno.mostrar(false);
		document.getElementById("LabelNombre").style.display='none';
		this.datosIdentificacion.nombre.mostrar(false);
		document.getElementById("LabelDenominacion").style.display='none';
		this.datosIdentificacion.razon.mostrar(false);
		
		document.getElementById("LabelTarjetaTributaria").style.display='block';
		this.datosIdentificacion.nombreTarjetaTrib.mostrar(true);
		this.datosIdentificacion.nombreTarjetaTrib.habilitar(true);

		this.datosIdentificacion.curp.habilitar(true);
		this.datosIdentificacion.rfc.habilitar(false);
		this.datosIdentificacion.copiaRFC.habilitar(false);
		document.getElementById("buttonContinuar").disabled = true;
		this.habilitarSeccion2(true);
		this.datosIdentificacion.curp.ponerFoco();

		/*
		setRFC();
	
		comboboxTipoImpuesto.requestFocus();
		*/
	}
	
	if (this.datosIdentificacion.rfc.procesoBandaOCodigo && (unRFC.length != 0) && (unNombre.length != 0)) {
		var copiaRfc = document.getElementById("textfieldConfirmarRFC");
		copiaRfc.value = unRFC;

		document.getElementById("LabelPaterno").style.display='none';
		this.datosIdentificacion.paterno.mostrar(false);
		document.getElementById("LabelMaterno").style.display='none';
		this.datosIdentificacion.materno.mostrar(false);
		document.getElementById("LabelNombre").style.display='none';
		this.datosIdentificacion.nombre.mostrar(false);
		document.getElementById("LabelDenominacion").style.display='none';
		this.datosIdentificacion.razon.mostrar(false);
		
		document.getElementById("LabelTarjetaTributaria").style.display='block';
		this.datosIdentificacion.nombreTarjetaTrib.mostrar(true);
		this.datosIdentificacion.nombreTarjetaTrib.ponerCampo(unNombre);
		this.datosIdentificacion.nombreTarjetaTrib.habilitar(false);
		this.datosIdentificacion.curp.habilitar(true);
		this.datosIdentificacion.rfc.habilitar(false);
		this.datosIdentificacion.copiaRFC.habilitar(false);
		document.getElementById("buttonContinuar").disabled = true;
		this.habilitarSeccion2(true);
		this.datosIdentificacion.curp.ponerFoco();
		/*
		setRFC();
		comboboxTipoImpuesto.requestFocus();
		*/
	}
};

DPA.prototype.habilitarSeccion2 = function(valor){
	valor = !valor;
	document.getElementById("checkboxNoAplicaPeriodo").disabled = valor;
	document.getElementById("comboboxDependencia").disabled = valor;
	document.getElementById("textfieldCadenaDependencia").disabled = valor;
	document.getElementById("buttonConfirmarDatos").disabled = valor;
	document.getElementById("buttonAgregarConcepto").disabled = valor;
	document.getElementById("buttonBorrarTodo").disabled = valor;
	
	document.getElementById("radiobuttonMensual").disabled = valor;
	document.getElementById("radiobuttonBimestral").disabled = valor;
	document.getElementById("radiobuttonTrimestral").disabled = valor;
	document.getElementById("radiobuttonCuatrimestral").disabled = valor;
	document.getElementById("radiobuttonSemestral").disabled = valor;
	document.getElementById("radiobuttonDelEjercicio").disabled = valor;
	document.getElementById("comboboxEjercicio").disabled = valor;
	document.getElementById("textfieldClaveReferenciaDPA").disabled = valor;
	document.getElementById("comboboxPeriodo").disabled = valor;
};

DPA.prototype.OnKeyUpImportesDPA = function (event, campo){
	var DelEjercicio = this.detalleConcepto.noAplicaPeriodo.tiposPeriodo[5];
	DelEjercicio.recuperarDeDestino();
	
	this.detalleConcepto.importeDPA.recuperarDeDestino();
	this.detalleConcepto.parteActualDPA.recuperarDeDestino();
	this.detalleConcepto.recargosDPA.recuperarDeDestino();
	this.detalleConcepto.multaDPA.recuperarDeDestino();
	this.detalleConcepto.compensacionesDPA.recuperarDeDestino();
	
	if ((campo==='textfieldACargo' || campo === 'textfieldParteActualizada' 
		|| campo === 'textfieldRecargos' || campo === 'textfieldMultaCorrecion'
		|| campo === 'textfieldCompensaciones')
			&& (DelEjercicio.valor==='checked')
			&& (this.detalleConcepto.claveRefDPA.caracter3 === '4')) {
		if (this.detalleConcepto.importeDPA.valor.length > 0
				|| this.detalleConcepto.parteActualDPA.valor.length > 0
				|| this.detalleConcepto.recargosDPA.valor.length > 0
				|| this.detalleConcepto.multaDPA.valor.length > 0
				|| this.detalleConcepto.compensacionesDPA.valor.length > 0){
			this.detalleConcepto.saldoFavorDPA.ponerCampo('');
			this.detalleConcepto.saldoFavorDPA.habilitar(false);
		}
		else {
			this.detalleConcepto.saldoFavorDPA.habilitar(true);
		}
	}
	
	
	if ((campo === 'textfieldSaldoFavor')
			&& (DelEjercicio.valor==='checked')
			&& (this.detalleConcepto.claveRefDPA.caracter3 === '4')) {
		this.detalleConcepto.saldoFavorDPA.recuperarDeDestino();
		if (this.detalleConcepto.saldoFavorDPA.valor.length > 0) {
			this.detalleConcepto.habilitarImportesDPA(false);
			this.detalleConcepto.compensacionesDPA.habilitar('0');
		} else {
			this.detalleConcepto.habilitarImportesDPA('1');
			this.detalleConcepto.compensacionesDPA.habilitar(true);
		}
	}
};

DPA.prototype.ponerOpcionPago = function(campo){
	
	if ((campo==='textfieldACargo' || campo === 'textfieldParteActualizada' 
		|| campo === 'textfieldRecargos' || campo === 'textfieldMultaCorrecion'
		|| campo === 'textfieldCompensaciones')) {
		this.opcionPago = 1;
		this.setCantidadesCalculadas();
	} else if(campo === 'textfieldIvaACargo' || campo === 'textfieldIvaParteActualizada'
		|| campo === 'textfieldIvaRecargos' || campo === 'textfieldIvaMultaCorrecion'){
		this.opcionPago = 2;
		this.setCantidadesCalculadas();
	}
};

DPA.prototype.OnKeyUpSinRFCNombre = function(event, campo){
	this.datosIdentificacion.curp.recuperarDeDestino();
	this.datosIdentificacion.paterno.recuperarDeDestino();
	this.datosIdentificacion.materno.recuperarDeDestino();
	this.datosIdentificacion.nombre.recuperarDeDestino();
	this.datosIdentificacion.razon.recuperarDeDestino();
	if ((this.datosIdentificacion.curp.valor.length > 0)
			|| (this.datosIdentificacion.paterno.valor.length > 0)
			|| (this.datosIdentificacion.materno.valor.length > 0)
			|| (this.datosIdentificacion.nombre.valor.length > 0)) {
		this.datosIdentificacion.razon.ponerCampo('');
		this.datosIdentificacion.razon.habilitar(false);
	} else if (this.datosIdentificacion.razon.valor.length > 0) {
		this.datosIdentificacion.curp.ponerCampo('');
		this.datosIdentificacion.curp.habilitar(false);

		this.datosIdentificacion.paterno.ponerCampo('');
		this.datosIdentificacion.paterno.habilitar(false);

		this.datosIdentificacion.materno.ponerCampo('');
		this.datosIdentificacion.materno.habilitar(false);

		this.datosIdentificacion.nombre.ponerCampo('');
		this.datosIdentificacion.nombre.habilitar(false);
	} else if ((this.datosIdentificacion.curp.valor.length == 0)
			&& (this.datosIdentificacion.paterno.valor.length == 0)
			&& (this.datosIdentificacion.materno.valor.length == 0)
			&& (this.datosIdentificacion.nombre.valor.length == 0)
			&& (this.datosIdentificacion.razon.valor.length == 0)) {
		this.datosIdentificacion.curp.habilitar(true);
		this.datosIdentificacion.paterno.habilitar(true);
		this.datosIdentificacion.materno.habilitar(true);
		this.datosIdentificacion.nombre.habilitar(true);
		this.datosIdentificacion.razon.habilitar(true);
	}
};

DPA.prototype.OnKeyUpClaveRefDPA = function(event, campo){
	this.detalleConcepto.claveRefDPA.onKeyUp(event);
	this.detalleConcepto.claveRefDPA.recuperarDeDestino();

	this.detalleConcepto.noAplicaPeriodo.recuperarDeDestino();
	var oldState = this.detalleConcepto.noAplicaPeriodo.valor;
	if (this.detalleConcepto.claveRefDPA.valor==="024000140") {
		this.detalleConcepto.noAplicaPeriodo.ponerCampo('checked');
		this.detalleConcepto.noAplicaPeriodo.OnClick();
		this.detalleConcepto.noAplicaPeriodo.habilitar(false);
		this.habilitarDebloquearImportes(true, false, false, false, false,
				false, false, false, false, false);
	}
	else {
		this.detalleConcepto.noAplicaPeriodo.ponerCampo(oldState);
		this.detalleConcepto.noAplicaPeriodo.habilitar(true);
		var DelEjercicio = this.detalleConcepto.noAplicaPeriodo.tiposPeriodo[5];
		DelEjercicio.recuperarDeDestino();
		if (DelEjercicio.valor==='checked') {
			if (this.detalleConcepto.claveRefDPA.valor.length >= 3) {
				if (this.detalleConcepto.claveRefDPA.caracter3 === '4') {
					var impDPA = false, parAct = false, rec = false, multaCorr = false, impIVA = false, parActIVA = false, recIVA = false, multaCorrIVA = false, saldoFavor = false, comp = false;
					impIVA = false;
					parActIVA = false;
					recIVA = false;
					multaCorrIVA = false;
					this.detalleConcepto.saldoFavorDPA.recuperarDeDestino();
					if (this.detalleConcepto.saldoFavorDPA.valor.length > 0) {
						comp = false;
						impDPA = false;
						parAct = false;
						rec = false;
						multaCorr = false;
					} else {
						comp = true;
						impDPA = true;
						parAct = true;
						rec = true;
						multaCorr = true;
					}
					this.detalleConcepto.importeDPA.recuperarDeDestino();
					this.detalleConcepto.parteActualDPA.recuperarDeDestino();
					this.detalleConcepto.recargosDPA.recuperarDeDestino();
					this.detalleConcepto.multaDPA.recuperarDeDestino();
					this.detalleConcepto.compensacionesDPA.recuperarDeDestino();
					if (this.detalleConcepto.importeDPA.valor.length > 0) {
						saldoFavor = false;
					} else if (this.detalleConcepto.parteActualDPA.valor.length > 0) {
						saldoFavor = false;
					} else if (this.detalleConcepto.recargosDPA.valor.length > 0) {
						saldoFavor = false;
					} else if (this.detalleConcepto.multaDPA.valor.length > 0) {
						saldoFavor = false;
					} else if (this.detalleConcepto.compensacionesDPA.valor.length > 0) {
						saldoFavor = false;
					} else {
						saldoFavor = true;
					}
					this.habilitarDebloquearImportes(impDPA, parAct, rec,
							multaCorr, impIVA, parActIVA, recIVA,
							multaCorrIVA, saldoFavor, comp);
				} else if (this.detalleConcepto.claveRefDPA.caracter3 === '6') {
					this.habilitarDebloquearImportes(true, true, true, true,
							true, true, true, true, false, false);
				} else if (this.detalleConcepto.claveRefDPA.caracter3 === '7') {
					this.habilitarDebloquearImportes(true, true, true, true,
							true, true, true, true, false, false);
				} else {
					this.habilitarDebloquearImportes(true, true, true, true,
							false, false, false, false, false, false);
				}
			} else {
				this.habilitarDebloquearImportes(true, true, true, true,
						false, false, false, false, false, false);
			}

		} else if (this.detalleConcepto.claveRefDPA.valor.length >= 3) {
			if (this.detalleConcepto.claveRefDPA.caracter3 === '4') {
				if (this.detalleConcepto.noAplicaPeriodo.valor==='checked') {
					this.habilitarDebloquearImportes(true, true, true, true,
							false, false, false, false, false, false);
				} else {
					this.habilitarDebloquearImportes(true, true, true, true,
							false, false, false, false, false, true);
				}
			} else if (this.detalleConcepto.claveRefDPA.caracter3 === '6') {
				this.habilitarDebloquearImportes(true, true, true, true,
						true, true, true, true, false, false);
			} else if (this.detalleConcepto.claveRefDPA.caracter3 === '7') {
				this.habilitarDebloquearImportes(true, true, true, true,
						true, true, true, true, false, false);
			} else {
				this.habilitarDebloquearImportes(true, true, true, true,
						false, false, false, false, false, false);
			}
		} else {
			this.habilitarDebloquearImportes(true, true, true, true, false,
					false, false, false, false, false);
		}
	}

	/*
	if (this.detalleConcepto.noAplicaPeriodo.valor ==='checked') {
		//addRadioButtonValues();
		//addComboBoxEjercicioValues();
		//addComboBoxPeriodoValues();
		radiobuttonMensual.setEnabled(false);
		radiobuttonBimestral.setEnabled(false);
		radiobuttonTrimestral.setEnabled(false);
		radiobuttonCuatrimestral.setEnabled(false);
		radiobuttonSemestral.setEnabled(false);
		radiobuttonDelEjercicio.setEnabled(false);

		comboboxPeriodo.setEnabled(false);
		comboboxEjercicio.setEnabled(false);
		
		;
	} else {
		
		comboboxPeriodo.setEnabled(true);
		comboboxEjercicio.setEnabled(true);
		
		;
	}
	*/
	/*
	checkboxNoAplicaPeriodo.addItemListener(this);
	*/
	
};

DPA.prototype.OnKeyUpCamposRFC = function(event, campo){
	if (campo==='textfieldRFC' || campo==='textfieldConfirmarRFC') {
		this.datosIdentificacion.rfc.ponerValor(document.getElementById(this.datosIdentificacion.rfc.campoDestino).value.toUpperCase());
		this.datosIdentificacion.rfc.OnKeyUp();
		var copia = document.getElementById(this.datosIdentificacion.copiaRFC.campoDestino).value.toUpperCase();
		this.datosIdentificacion.copiaRFC.ponerValor(copia);
		
		if ((this.datosIdentificacion.rfc.valor.length > 0)
				|| (this.datosIdentificacion.copiaRFC.valor.length  > 0)){
			document.getElementById("buttonContinuarSinRFC").disabled = true;
		}
		else {
			document.getElementById("buttonContinuarSinRFC").disabled = false;
		}
	}
};

DPA.prototype.setCantidadesCalculadas = function(){
	var mandarMensaje = false;
	
	if(this.validarComp){
		this.totalContribucionesDPA =
			this.detalleConcepto.importeDPA.valorNumerico() + this.detalleConcepto.parteActualDPA.valorNumerico()
			+ this.detalleConcepto.recargosDPA.valorNumerico() + this.detalleConcepto.multaDPA.valorNumerico();
		if ((this.detalleConcepto.compensacionesDPA.habilitado()) && (this.detalleConcepto.compensacionesDPA.valor.length > 0)) {
			if(this.totalContribucionesDPA < this.detalleConcepto.compensacionesDPA.valorNumerico()){
				mandarMensaje = true;
			}
		}
	}
	if (this.opcionPago == 1) {
		this.totalContribucionesDPA =
			this.detalleConcepto.importeDPA.valorNumerico() + this.detalleConcepto.parteActualDPA.valorNumerico()
			+ this.detalleConcepto.recargosDPA.valorNumerico() + this.detalleConcepto.multaDPA.valorNumerico();
	} else if (this.opcionPago == 2) {
		this.detalleConcepto.importeIVA.recuperarDeDestino();
		this.detalleConcepto.parteActualIVA.recuperarDeDestino();
		this.detalleConcepto.recargosIVA.recuperarDeDestino();
		this.detalleConcepto.multaIVA.recuperarDeDestino();
		this.totalContribucionesIVA =
			this.detalleConcepto.importeIVA.valorNumerico() + this.detalleConcepto.parteActualIVA.valorNumerico()
			+ this.detalleConcepto.recargosIVA.valorNumerico() + this.detalleConcepto.multaIVA.valorNumerico();
	}

	if (this.totalContribucionesIVA >= 0) {
		this.cantidadPagarIVA = this.totalContribucionesIVA;
		this.detalleConcepto.cantPagarIVA.ponerCampo('' + this.cantidadPagarIVA);
	}
	if(this.detalleConcepto.compensacionesDPA.valor.length > 0){
		this.cantidadPagar = this.totalContribucionesDPA - this.detalleConcepto.compensacionesDPA.valorNumerico();
	}else{
		this.cantidadPagar = this.totalContribucionesDPA;
	}
	this.totalContribuciones = (this.totalContribucionesDPA + this.totalContribucionesIVA);
	this.detalleConcepto.cantPagarDPA.ponerCampo('' + this.cantidadPagar);
	
	this.detalleConcepto.totalEfecPagado.ponerValor('' + (this.cantidadPagar + this.cantidadPagarIVA));
	this.opcionPago = 0;
	
	return mandarMensaje;
};

DPA.prototype.borrarConcepto = function(){
	this.detalleConcepto.cantPagarIVA.ponerCampo('0');
	this.detalleConcepto.cantPagarDPA.ponerCampo('0');
	var total = this.detalleConcepto.totalEfecPagado.valorNumerico() - (this.cantidadPagar + this.cantidadPagarIVA);
	this.detalleConcepto.totalEfecPagado.ponerValor('' + total);
};

DPA.prototype.reiniciarDetalle = function(){
	this.detalleConcepto.noAplicaPeriodo.OnClick();
	this.detalleConcepto.claveRefDPA.ponerCampo('');
	this.detalleConcepto.cadenaDependencia.ponerCampo('');
	this.detalleConcepto.claveRefDPA.habilitar(true);
	this.detalleConcepto.cadenaDependencia.habilitar(true);
	this.detalleConcepto.saldoFavorDPA.ponerCampo('');
	this.detalleConcepto.saldoFavorDPA.habilitar(false);
	this.detalleConcepto.limpiarImportes();
	this.detalleConcepto.limpiarImportesIVA();
	this.detalleConcepto.habilitarImportesDPA('1');
	this.detalleConcepto.habilitarImportesIVA('0');
	this.detalleConcepto.claveRefDPA.ponerFoco();
};

DPA.prototype.habilitarDebloquearImportes = function(impDPA, parAct, rec, multaCorr,
		impIVA, parActIVA, recIVA, multaCorrIVA, saldoFavor, comp){

	if(!impDPA){
		this.detalleConcepto.importeDPA.ponerCampo('');
		this.opcionPago = 1;
		this.setCantidadesCalculadas();
	}
	this.detalleConcepto.importeDPA.habilitar(impDPA);
	if(!parAct){
		this.detalleConcepto.parteActualDPA.ponerCampo('');
		this.opcionPago = 1;
		this.setCantidadesCalculadas();
	}
	this.detalleConcepto.parteActualDPA.habilitar(parAct);
	if(!rec){
		this.detalleConcepto.recargosDPA.ponerCampo('');
		this.opcionPago = 1;
		this.setCantidadesCalculadas();
	}
	this.detalleConcepto.recargosDPA.habilitar(rec);
	if(!multaCorr){
		this.detalleConcepto.multaDPA.ponerCampo('');
		this.opcionPago = 1;
		this.setCantidadesCalculadas();
	}
	this.detalleConcepto.multaDPA.habilitar(multaCorr);
	if(!impIVA){
		this.detalleConcepto.importeIVA.ponerCampo('');
		this.opcionPago = 2;
		this.setCantidadesCalculadas();
	}
	this.detalleConcepto.importeIVA.habilitar(impIVA);
	if(!parActIVA){
		this.detalleConcepto.parteActualIVA.ponerCampo('');
		this.opcionPago = 2;
		this.setCantidadesCalculadas();
	}
	this.detalleConcepto.parteActualIVA.habilitar(parActIVA);
	if(!recIVA){
		this.detalleConcepto.recargosIVA.ponerCampo('');
		this.opcionPago = 2;
		this.setCantidadesCalculadas();
	}
	this.detalleConcepto.recargosIVA.habilitar(recIVA);
	if(!multaCorrIVA){
		this.detalleConcepto.multaIVA.ponerCampo('');
		this.opcionPago = 2;
		this.setCantidadesCalculadas();
	}
	this.detalleConcepto.multaIVA.habilitar(multaCorrIVA);
	if(!saldoFavor){
		this.detalleConcepto.saldoFavorDPA.ponerCampo('');
		this.setCantidadesCalculadas();
		this.opcionPago = 1;
	}
	this.detalleConcepto.saldoFavorDPA.habilitar(saldoFavor);
	if(!comp){
		this.detalleConcepto.compensacionesDPA.ponerCampo('');
		this.setCantidadesCalculadas();
		this.opcionPago = 1;
	}
	this.detalleConcepto.compensacionesDPA.habilitar(comp);
};

DPA.prototype.getXMLIVA = function(){

	var res = '';
	
	if(this.conValoresIVA())	//RMA 6110090 DAC
		res = XMLConceptos(claveInfIVA, this.valoresIVA);
	
	return res;
};

DPA.prototype.getXMLDPA = function(){

	var res = XMLConceptos(claveInfDPA, this.valoresDPA);
	
	return res;
};

function XMLConceptos (claveInf, valores){

	var stringValue = '';
	stringValue = stringValue + "<CONCEPTOS>";
	stringValue = stringValue
			+ "<CONCEPTO><CLAVE>"
			+ claveInf[1]
			+ "</CLAVE><DESCRIPCION>"
			+ formatoXML( descripConcepto[0])
			+ "</DESCRIPCION><VALOR>"
			+ formatoXML(claveInf[0])
			+ "</VALOR><VALORIMPRESION>"
			+ formatoXML(claveInf[0])
			+ "</VALORIMPRESION></CONCEPTO>";

	if (valores[2] != null) {
		stringValue = stringValue
				+ "<CONCEPTO><CLAVE>"
				+ claveInf[2]
				+ "</CLAVE><DESCRIPCION>"
				+ formatoXML(descripConcepto[2])
				+ "</DESCRIPCION><VALOR>";
				if(claveInf[2]!=='12002'){
					stringValue = stringValue
					+ valores[2]
					+ "</VALOR><VALORIMPRESION>"
					+ formatoXML(this.dpa.detalleConcepto.noAplicaPeriodo.periodoSeleccionado().periodo.recuperarNombre())
					+ "</VALORIMPRESION></CONCEPTO>";
				}else{
					stringValue = stringValue
					+ parseInt(fechaActual.getMonth() + 1, 10)
					+ "</VALOR><VALORIMPRESION>"
					+ formatoXML(this.dpa.detalleConcepto.noAplicaPeriodo.tiposPeriodo[0].periodo.meses[fechaActual.getMonth()])
					+ "</VALORIMPRESION></CONCEPTO>";
				}
	}

	if (valores[3] != null) {
		stringValue = stringValue
				+ "<CONCEPTO><CLAVE>"
				+ claveInf[3]
				+ "</CLAVE><DESCRIPCION>"
				+ formatoXML(descripConcepto[3])
				+ "</DESCRIPCION><VALOR>";
				if(claveInf[3]!=='12027'){
					stringValue = stringValue
					+ valores[3]
					+ "</VALOR><VALORIMPRESION>"
					+ formatoXML(valores[3])
					+ "</VALORIMPRESION></CONCEPTO>";
				}else{
					stringValue = stringValue
					+ fechaActual.getFullYear()
					+ "</VALOR><VALORIMPRESION>"
					+ fechaActual.getFullYear()
					+ "</VALORIMPRESION></CONCEPTO>";
				}
	}

	if (valores[4] != null) {
		stringValue = stringValue
				+ "<CONCEPTO><CLAVE>"
				+ claveInf[4]
				+ "</CLAVE><DESCRIPCION>"
				+ formatoXML(descripConcepto[4])
				+ "</DESCRIPCION><VALOR>"
				+ formatoXML(valores[4])
				+ "</VALOR><VALORIMPRESION>"
				+ formatoXML(this.dpa.detalleConcepto.noAplicaPeriodo.periodoSeleccionado().periodo.recuperarNombre())
				+ "</VALORIMPRESION></CONCEPTO>";
	}

	if (valores[20] != null) {
		stringValue = stringValue
				+ "<CONCEPTO><CLAVE>"
				+ claveInf[20]
				+ "</CLAVE><DESCRIPCION>"
				+ formatoXML(descripConcepto[20])
				+ "</DESCRIPCION><VALOR>"
				+ valores[20]
				+ "</VALOR><VALORIMPRESION>"
				+ formatoXML(valores[20])			//Esta fecha debe estar en el formato dd/mm/aaaa
				+ "</VALORIMPRESION></CONCEPTO>";
	}
	
	if (valores[25] != null) {
		stringValue = stringValue
				+ "<CONCEPTO><CLAVE>"
				+ claveInf[25]
				+ "</CLAVE><DESCRIPCION>"
				+ formatoXML(descripConcepto[25])
				+ "</DESCRIPCION><VALOR>"
				+ valores[25]
				+ "</VALOR><VALORIMPRESION>"
				+ formatoXML("$" + this.dpa.detalleConcepto.saldoFavorDPA.valorConMascara + ".00")
				+ "</VALORIMPRESION></CONCEPTO>";
	}

	var campo = new Numerico('','99,999,999,999,999');
	for (var cont1 = 5; cont1 <= 18; cont1++) {
		if (valores[cont1] == null)
			continue;
		campo.ponerValor(valores[cont1]);
		stringValue = stringValue
				+ "<CONCEPTO><CLAVE>"
				+ claveInf[cont1]
				+ "</CLAVE><DESCRIPCION>"
				+ formatoXML(descripConcepto[cont1])
				+ "</DESCRIPCION><VALOR>"
				+ valores[cont1]
				+ "</VALOR><VALORIMPRESION>"
				+ formatoXML("$" + campo.valorConMascara + ".00")
				+ "</VALORIMPRESION></CONCEPTO>";
	}

	if (valores[21] != null) {
		campo.ponerValor(valores[21]);
		stringValue = stringValue
				+ "<CONCEPTO><CLAVE>"
				+ claveInf[21]
				+ "</CLAVE><DESCRIPCION>"
				+ formatoXML(descripConcepto[21])
				+ "</DESCRIPCION><VALOR>"
				+ valores[21]
				+ "</VALOR><VALORIMPRESION>"
				+ formatoXML("$" + campo.valorConMascara + ".00")
				+ "</VALORIMPRESION></CONCEPTO>";
	}

	if (valores[19] != null) {
		campo.ponerValor(valores[19]);
		stringValue = stringValue
				+ "<CONCEPTO><CLAVE>"
				+ claveInf[19]
				+ "</CLAVE><DESCRIPCION>"
				+ formatoXML(descripConcepto[19])
				+ "</DESCRIPCION><VALOR>"
				+ valores[19]
				+ "</VALOR><VALORIMPRESION>"
				+ formatoXML("$" + campo.valorConMascara + ".00")
				+ "</VALORIMPRESION></CONCEPTO>";
	}

	for (var cont1 = 22; cont1 <= 26; cont1++) {
		if (valores[cont1] == null)
			continue;
		if (cont1 == 25)
			continue;
		campo.ponerValor(valores[cont1]);
		stringValue = stringValue
				+ "<CONCEPTO><CLAVE>"
				+ claveInf[cont1]
				+ "</CLAVE><DESCRIPCION>"
				+ formatoXML(descripConcepto[cont1])
				+ "</DESCRIPCION><VALOR>"
				+ valores[cont1]
				+ "</VALOR><VALORIMPRESION>"
				+ formatoXML("$" + campo.valorConMascara + ".00")
				+ "</VALORIMPRESION></CONCEPTO>";
	}

	if (valores[32] != null) {
		stringValue = stringValue
				+ "<CONCEPTO><CLAVE>"
				+ claveInf[32]
				+ "</CLAVE><DESCRIPCION>"
				+ formatoXML(descripConcepto[32])
				+ "</DESCRIPCION><VALOR>" + valores[32]
				+ "</VALOR><VALORIMPRESION>"
				+ formatoXML(valores[32])
				+ "</VALORIMPRESION></CONCEPTO>";
	}

	if (valores[33] != null) {
		stringValue = stringValue
				+ "<CONCEPTO><CLAVE>"
				+ claveInf[33]
				+ "</CLAVE><DESCRIPCION>"
				+ formatoXML(descripConcepto[33])
				+ "</DESCRIPCION><VALOR>" + valores[33]
				+ "</VALOR><VALORIMPRESION>"
				+ formatoXML(valores[33])
				+ "</VALORIMPRESION></CONCEPTO>";
	}

	stringValue = stringValue + "</CONCEPTOS>";

	return stringValue;
};

DPA.prototype.appEnviaDatosIVA = function(){
	
	var res = appEnviaConceptos(claveInfIVA, this.valoresIVA);
	
	return res;
	
};

DPA.prototype.appEnviaDatosDPA = function(){
	
	var res = appEnviaConceptos(claveInfDPA, this.valoresDPA);
	
	return res;
	
};

function appEnviaConceptos(claveInf, valores){
	var stringValue;
	stringValue = "";

	for (var cont1 = 2; cont1 <= 4; cont1++) {
		if (valores[cont1] != null) {
			if(cont1==2){
				if(claveInf[2]!=='12002'){
					stringValue = stringValue + claveInf[cont1] + "="
					+ valores[cont1] + "|";
				}else{
					stringValue = stringValue + claveInf[cont1] + "="
					+ parseInt(fechaActual.getMonth() + 1, 10) + "|";
				}
				continue;
			}else if(cont1==3){
				if(claveInf[3]!=='12027'){
					stringValue = stringValue + claveInf[cont1] + "="
					+ valores[cont1] + "|";
				}else{
					stringValue = stringValue + claveInf[cont1] + "="
					+ fechaActual.getFullYear() + "|";
				}
				continue;
			}
			stringValue = stringValue + claveInf[cont1] + "="
					+ valores[cont1] + "|";
		}
	}

	if (valores[20] != null) {
		stringValue = stringValue + claveInf[20] + "="
				+ valores[20] + "|";
	}

	if (valores[25] != null) {
		stringValue = stringValue + claveInf[25] + "="
				+ valores[25] + "|";
	}

	for (var cont1 = 5; cont1 <= 18; cont1++) {
		if (valores[cont1] != null) {
			stringValue = stringValue + claveInf[cont1] + "="
					+ valores[cont1] + "|";
		}
	}

	if (valores[21] != null) {
		stringValue = stringValue + claveInf[21] + "="
				+ valores[21] + "|";
	}

	if (valores[19] != null) {
		stringValue = stringValue + claveInf[19] + "="
				+ valores[19] + "|";
	}

	for (var cont1 = 22; cont1 <= 26; cont1++) {
		if(cont1 == 25)
			continue;
		if (valores[cont1] != null) {
			stringValue = stringValue + claveInf[cont1] + "="
					+ valores[cont1] + "|";
		}
	}

	if (valores[32] != null) {
		stringValue = stringValue + claveInf[32] + "="
				+ valores[32] + "|";
	}

	if (valores[33] != null) {
		stringValue = stringValue + claveInf[33] + "="
				+ valores[33] + "|";
	}
	
	return stringValue;

	
};

DPA.prototype.appEnviaImpresionIVA = function(){
	var res = '';
	
	if(this.conValoresIVA())	//RMA 6110090 DAC
		res = enviaImpresion(claveInfIVA, this.valoresIVA);
	
	return res;
	
};

DPA.prototype.appEnviaImpresionDPA = function(){
	var	res = enviaImpresion(claveInfDPA, this.valoresDPA);
	
	return res;
	
};

function enviaImpresion(claveInf, valores){
	var stringValue;
	var campo = new Numerico('','99,999,999,999,999', '');
	
	stringValue = "";

	stringValue = stringValue + claveInf[0] + "|";

	if (valores[2] != null) {
		if(claveInf[2]!=='12002'){
			stringValue = stringValue
					+ descripConcepto[2] + ":"
					+ this.dpa.detalleConcepto.noAplicaPeriodo.periodoSeleccionado().periodo.recuperarNombre()
					+ "|";
		}else{
			stringValue = stringValue
			+ descripConcepto[2] + ":"
			+ this.dpa.detalleConcepto.noAplicaPeriodo.tiposPeriodo[0].periodo.meses[fechaActual.getMonth()]
			+ "|";
		}
	}

	if (valores[3] != null) {
		if(claveInf[3]!=='12027'){
			stringValue = stringValue
					+ descripConcepto[3] + ":"
					+ valores[3] + "|";
		}else{
			stringValue = stringValue
			+ descripConcepto[3] + ":"
			+ fechaActual.getFullYear() + "|";
		}
	}

	if (valores[4] != null) {
		stringValue = stringValue
				+ descripConcepto[4]
				+ ":"
				+ this.dpa.detalleConcepto.noAplicaPeriodo.periodoSeleccionado().periodo.periodicidad
				+ "|";
	}
	if (valores[5] != null) {
		campo.ponerValor(valores[5]);
		stringValue = stringValue
				+ descripConcepto[5] + ":"
				+ campo.valorConMascara + "|";
	}
	if (valores[25] != null) {
		campo.ponerValor(valores[25]);
		stringValue = stringValue
				+ descripConcepto[25] + ":"
				+ campo.valorConMascara  + "|";
	}
	if (valores[6] != null) {
		campo.ponerValor(valores[6]);
		stringValue = stringValue
				+ descripConcepto[6] + ":"
				+ campo.valorConMascara  + "|";
	}
	for (var cont1 = 7; cont1 <= 10; cont1++) {
		if ((valores[cont1] != null) && (cont1 != 10)) {
			campo.ponerValor(valores[cont1]);
			stringValue = stringValue
					+ descripConcepto[cont1]
					+ ":"
					+ campo.valorConMascara 
					+ "|";
		}
	}
	for (var cont1 = 11; cont1 <= 18; cont1++) {
		if (valores[cont1] != null) {
			campo.ponerValor(valores[cont1]);
			stringValue = stringValue
					+ descripConcepto[cont1]
					+ ":("
					+ campo.valorConMascara 
					+ ")|";
		}
	}
	if (valores[22] != null) {
		campo.ponerValor(valores[22]);
		stringValue = stringValue
				+ descripConcepto[22]
				+ ":" + campo.valorConMascara 
				+ "|";
	}
	if (valores[20] != null) {
		campo.ponerValor(valores[21]);
		stringValue = stringValue
				+ descripConcepto[20]
				+ ":"
				+ valores[20]		//Fecha en formato dd/mm/aaaa
				+ "|";
		stringValue = stringValue
				+ descripConcepto[21]
				+ ":" + campo.valorConMascara 
				+ "|";
	}
	for (var cont1 = 23; cont1 <= 26; cont1++) {
		if(cont1 == 25)
			continue;
		if (valores[cont1] != null) {
			campo.ponerValor(valores[cont1]);
			stringValue = stringValue
					+ descripConcepto[cont1]
					+ ":"
					+ campo.valorConMascara 
					+ "|";
		}
	}
	if (valores[32] != null) {
		stringValue = stringValue
				+ descripConcepto[32]
				+ ":" + valores[32] + "|";
	}
	if (valores[33] != null) {
		stringValue = stringValue
				+ descripConcepto[33]
				+ ":" + valores[33] + "|";
	}

	return stringValue;
	
};

DPA.prototype.appCadenaOriginalDPA = function(){
	
	var res = cadenaOriginal(claveInfDPA, this.valoresDPA);
	
	return res;
	
};

DPA.prototype.appCadenaOriginalIVA = function(){
	
	var res = cadenaOriginal(claveInfIVA, this.valoresIVA);
	
	return res;
	
};

function cadenaOriginal(claveInf, valores){
	var stringValue = "";

	for (var cont1 = 2; cont1 <= 4; cont1++) {
		if (valores[cont1] != null) {
			if(cont1==2){
				if(claveInf[2]!=='12002'){
					stringValue = stringValue + claveInf[cont1] + "="
							+ valores[cont1] + "|";
				}else{
					stringValue = stringValue + claveInf[cont1] + "="
					+ parseInt(fechaActual.getMonth() + 1, 10) + "|";
				}
				continue;
			}else if(cont1==3){
				if(claveInf[3]!=='12027'){
					stringValue = stringValue + claveInf[cont1] + "="
							+ valores[cont1] + "|";
				}else{
					stringValue = stringValue + claveInf[cont1] + "="
					+ fechaActual.getFullYear() + "|";
				}
				continue;
			}
			stringValue = stringValue + claveInf[cont1] + "="
			+ valores[cont1] + "|";
		}
	}

	if (valores[20] != null) {
		stringValue = stringValue + claveInf[20] + "="
				+ valores[20] + "|";
	}
	
	if (valores[25] != null) {
		stringValue = stringValue + claveInf[25] + "="
				+ valores[25] + "|";
	}
	
	
	for (var cont1 = 5; cont1 <= 18; cont1++) {
		if (valores[cont1] != null) {
			stringValue = stringValue + claveInf[cont1] + "="
					+ valores[cont1] + "|";
		}
	}
	if (valores[21] != null) {
		stringValue = stringValue + claveInf[21] + "="
				+ valores[21] + "|";
	}
	if (valores[19] != null) {
		stringValue = stringValue + claveInf[19] + "="
				+ valores[19] + "|";
	}
	for (var cont1 = 22; cont1 <= 26; cont1++) {
		if(cont1 == 25)
			continue;
		if (valores[cont1] != null) {
			stringValue = stringValue + claveInf[cont1] + "="
					+ valores[cont1] + "|";
		}
	}
	if (valores[32] != null) {
		stringValue = stringValue + claveInf[32] + "="
				+ valores[32] + "|";
	}

	if (valores[33] != null) {
		stringValue = stringValue + claveInf[33] + "="
				+ valores[33] + "|";
	}
	
	return stringValue;
};

function formatoXML(valor){
	campo = new RAZON('','','');
	campo.ponerValor(valor);
	
	var res = campo.convertirUNICODE(valor);
	return res;
};

DPA.prototype.validarLimpioCampo= function(campo){
	if(dpa.datosIdentificacion.validarLimpioCampo(campo)==false)
		dpa.detalleConcepto.validarLimpioCampo(campo);

	this.OnKeyUpCamposRFC(event, campo);
};//Mediador entre IU y clases
//Elaborado por David Aguilera Cuevas jul2016
//

	var totalEfectPagado;
	var datosConfirmados;
	var indPAGOS = 0;
	
function reiniciar(){
	
	datosConfirmados = false;
	IUinicial();
	crearDPA();
};

//Presentaci�n inicial
function IUinicial(){

	document.getElementById("textfieldRFC").disabled = false;
	document.getElementById("textfieldRFC").style.backgroundColor=ColorEnabled;
	document.getElementById("textfieldRFC").value = '';
	document.getElementById("textfieldConfirmarRFC").disabled = false;
	document.getElementById("textfieldConfirmarRFC").style.backgroundColor=ColorEnabled;
	document.getElementById("textfieldConfirmarRFC").value = '';
	document.getElementById("buttonContinuar").disabled = false;
	document.getElementById("buttonContinuarSinRFC").disabled = false;

	document.getElementById("textfieldCURP").value = '';
	document.getElementById("textfieldCURP").disabled = true;
	document.getElementById("textfieldCURP").style.display='block';
	document.getElementById("textfieldCURP").style.backgroundColor=ColorDisabled;
	document.getElementById("LabelCURP").style.display='block';
	
	document.getElementById("LabelTarjetaTributaria").style.display='none';
	document.getElementById("textfieldNombreTarjetaTributaria").style.display='none';
	document.getElementById("textfieldNombreTarjetaTributaria").value ='';
	document.getElementById("textfieldNombreTarjetaTributaria").disabled = true;
	document.getElementById("textfieldNombreTarjetaTributaria").style.backgroundColor=ColorDisabled;
	
	document.getElementById("textfieldPaterno").disabled = true;
	document.getElementById("textfieldPaterno").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldPaterno").value = '';
	document.getElementById("textfieldPaterno").style.display='block';
	document.getElementById("LabelPaterno").style.display='block';
	
	document.getElementById("textfieldMaterno").disabled = true;
	document.getElementById("textfieldMaterno").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldMaterno").value = '';
	document.getElementById("textfieldMaterno").style.display='block';
	document.getElementById("LabelMaterno").style.display='block';
	
	document.getElementById("textfieldNombre").disabled = true;
	document.getElementById("textfieldNombre").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldNombre").value = '';
	document.getElementById("textfieldNombre").style.display='block';
	document.getElementById("LabelNombre").style.display='block';

	document.getElementById("LabelDenominacion").style.display='none';
	document.getElementById("textfieldRazonSocial").style.display='none';
	document.getElementById("textfieldRazonSocial").value ='';
		
	document.getElementById("comboboxDependencia").disabled = true;
	document.getElementById("comboboxDependencia").style.backgroundColor=ColorDisabled;
	document.getElementById("checkboxNoAplicaPeriodo").disabled = true;
	document.getElementById("checkboxNoAplicaPeriodo").style.backgroundColor=ColorDisabled;
	document.getElementById("radiobuttonMensual").disabled = true;
	document.getElementById("radiobuttonMensual").style.backgroundColor=ColorDisabled;
	document.getElementById("radiobuttonBimestral").disabled = true;
	document.getElementById("radiobuttonBimestral").style.backgroundColor=ColorDisabled;
	document.getElementById("radiobuttonTrimestral").disabled = true;
	document.getElementById("radiobuttonTrimestral").style.backgroundColor=ColorDisabled;
	document.getElementById("radiobuttonCuatrimestral").disabled = true;
	document.getElementById("radiobuttonCuatrimestral").style.backgroundColor=ColorDisabled;
	document.getElementById("radiobuttonSemestral").disabled = true;
	document.getElementById("radiobuttonSemestral").style.backgroundColor=ColorDisabled;
	document.getElementById("radiobuttonDelEjercicio").disabled = true;
	document.getElementById("radiobuttonDelEjercicio").style.backgroundColor=ColorDisabled;
	document.getElementById("comboboxEjercicio").disabled = true;
	document.getElementById("comboboxEjercicio").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldClaveReferenciaDPA").disabled = true;
	document.getElementById("textfieldClaveReferenciaDPA").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldClaveReferenciaDPA").value = '';
	document.getElementById("comboboxPeriodo").disabled = true;
	document.getElementById("comboboxPeriodo").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldCadenaDependencia").disabled = true;
	document.getElementById("textfieldCadenaDependencia").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldCadenaDependencia").value = '';
	document.getElementById("textfieldSaldoFavor").disabled = true;
	document.getElementById("textfieldSaldoFavor").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldSaldoFavor").value = '';
	document.getElementById("textfieldACargo").disabled = true;
	document.getElementById("textfieldACargo").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldACargo").value = '';
	document.getElementById("textfieldParteActualizada").disabled = true;
	document.getElementById("textfieldParteActualizada").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldParteActualizada").value = '';
	document.getElementById("textfieldRecargos").disabled = true;
	document.getElementById("textfieldRecargos").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldRecargos").value = '';
	document.getElementById("textfieldMultaCorrecion").disabled = true;
	document.getElementById("textfieldMultaCorrecion").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldMultaCorrecion").value = '';
	document.getElementById("textfieldCompensaciones").disabled = true;
	document.getElementById("textfieldCompensaciones").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldCompensaciones").value = '';
	document.getElementById("textfieldCantidadPagar").disabled = true;
	document.getElementById("textfieldCantidadPagar").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldTotalEfectivamentePagado").disabled = true;
	document.getElementById("textfieldTotalEfectivamentePagado").style.backgroundColor=ColorDisabled;

	document.getElementById("textfieldIvaACargo").disabled = true;
	document.getElementById("textfieldIvaACargo").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldIvaACargo").value = '';
	document.getElementById("textfieldIvaParteActualizada").disabled = true;
	document.getElementById("textfieldIvaParteActualizada").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldIvaParteActualizada").value = '';
	document.getElementById("textfieldIvaRecargos").disabled = true;
	document.getElementById("textfieldIvaRecargos").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldIvaRecargos").value = '';
	document.getElementById("textfieldIvaMultaCorrecion").disabled = true;
	document.getElementById("textfieldIvaMultaCorrecion").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldIvaMultaCorrecion").value = '';
	document.getElementById("textfieldTotalIva").disabled = true;
	document.getElementById("textfieldTotalIva").style.backgroundColor=ColorDisabled;
	document.getElementById("listDetalle").disabled = true;
	document.getElementById("listDetalle").style.backgroundColor=ColorDisabled;

	document.getElementById("buttonAgregarConcepto").disabled = true;
	document.getElementById("buttonBorrarConcepto").disabled = true;
	document.getElementById("buttonConfirmarDatos").disabled = true;
	document.getElementById("buttonBorrarTodo").disabled = true;
	

	document.getElementById("textfieldRFC").focus();
	
	
	totalEfectPagado = new Numerico('','99,999,999,999,999', 'textfieldTotalEfectivamentePagado');
	
	totalEfectPagado.claveInformatica = '10017';
	totalEfectPagado.descripcion = 'Total efectivamente pagado';
	totalEfectPagado.ponerCampo('0');
	

	var labelVersion = document.getElementById("LabelVersion");
	if (PARAM_TARJETA_TRIBUTARIA === 'V')
		labelVersion.innerHTML = "Ver. 5.1   20190716   [Derechos-V ABM]";
	else
		labelVersion.innerHTML = "Ver. 5.1   20190716   [Derechos-I ABM]";
	
	
}

function crearDPA(){
	dpa = new DPA();
	if(pagos.length>0)
		dpa.sinRFC = pagos[0].sinRFC;
}

function crearDPAConRFC(){
	dpa.continuar(false);
};

function crearDPASinRFC(){
	dpa.continuar(true);
};

function agregarConcepto(){
	var res = false;
	
	dpa.validar();
	if(dpa.errorValidacion==false){
		var noDPAs = 0;

		for (var cont1 = 0; cont1 < pagos.length; cont1++) {
			var dpaPaso = pagos[cont1];
			if(dpaPaso.valoresDPA[28]==="47")
				noDPAs++;
		}
		
		if (pagos.length >= PARAM_NUMERO_CONCEPTOS) {
			alert("Solo se permite capturar "
							+ PARAM_NUMERO_CONCEPTOS
							+ " conceptos\npor Derechos, Productos y Aprovechamientos.");
		}else{
			try{
				dpa.ponerResultadoDPA();
				if(dpa.conValoresIVA())
					dpa.ponerResultadoIVA();
				pagos[indPAGOS]= dpa;
				indPAGOS++;
				var total = totalEfectPagado.valorNumerico() + dpa.detalleConcepto.totalEfecPagado.valorNumerico();
				totalEfectPagado.ponerCampo(total);
				crearDPA();
				IUSiguienteDPA();
				res = true;
			}catch(exepcion){
				alert(exepcion);
			}
		}
	}
	
	return res;
};

function IUSiguienteDPA(){

	dpaCero = pagos[0];
	dpa.datosIdentificacion.dependencia.seleccionar(dpaCero.datosIdentificacion.dependencia.indice);
	dpa.datosIdentificacion.dependencia.habilitar(false);
	dpa.detalleConcepto.noAplicaPeriodo.habilitar(true);
	dpa.reiniciarDetalle();
	document.getElementById("buttonBorrarConcepto").disabled = false;
	document.getElementById("buttonBorrarTodo").disabled = false;
};

function comboboxDependenciaOnChange(){
	var res = true;
	
	dpa.datosIdentificacion.dependencia.OnChange();
	
	return res;
};

function checkboxNoAplicaPeriodoOnClick(){
	var res = true;

	dpa.detalleConcepto.noAplicaPeriodo.OnClick();
	dpa.detalleConcepto.claveRefDPA.ponerCampo('');
	dpa.detalleConcepto.cadenaDependencia.ponerCampo('');
	dpa.detalleConcepto.limpiarImportes();
	dpa.detalleConcepto.limpiarImportesIVA();
	dpa.detalleConcepto.habilitarImportesDPA('1');
	dpa.detalleConcepto.habilitarImportesIVA('0');
	dpa.detalleConcepto.claveRefDPA.ponerFoco();
	return res;
};

function radiobuttonOnClick(rb){
	var periodoASel = dpa.detalleConcepto.noAplicaPeriodo.tiposPeriodo[rb];
	var periodoSel = dpa.detalleConcepto.noAplicaPeriodo.periodoSeleccionado();
	if(periodoASel.valor!=='checked'){
		periodoSel.ponerValor('unchecked');
		dpa.detalleConcepto.noAplicaPeriodo.tiposPeriodo[rb].OnChange(rb);
		dpa.detalleConcepto.limpiarImportes();
		dpa.detalleConcepto.limpiarImportesIVA();
		dpa.detalleConcepto.habilitarImportesDPA('1');
		dpa.detalleConcepto.habilitarImportesIVA('0');
		dpa.detalleConcepto.claveRefDPA.ponerCampo('');
	}
	
};

function comboboxEjercicioOnChange(){
	dpa.detalleConcepto.ejercicio.OnChange();
	dpa.detalleConcepto.claveRefDPA.ponerCampo('');
};

function comboboxPeriodoOnChange(){
	dpa.detalleConcepto.periodo.OnChange();
	dpa.detalleConcepto.claveRefDPA.ponerCampo('');
};

function detallePerdioFoco(campo){
	dpa.detalleConcepto.campoPerdioFoco(campo);
};

function detalleGanoFoco(campo){
	dpa.detalleConcepto.campoGanoFoco(campo);
};

function detalleOnKeyDown(event, campo){
	return dpa.detalleConcepto.onKeyDown(event, campo);
};

function detalleOnKeyPress(event, campo){
	return dpa.detalleConcepto.onKeyyPress(event, campo);
}

function detalleOnKeyUp(event, campo){
	return dpa.detalleConcepto.onKeyUp(event, campo);
};

function detalleOnChange(event, campo){
	event.returnValue = dpa.detalleConcepto.onChange(event, campo);
	return event.returnValue; 
};

function identificacionOnKeyDown(event, campo){
	event.returnValue = dpa.datosIdentificacion.onKeyDown(event, campo); 
	return event.returnValue;
};

function identificacionOnKeyPress(event, campo){
	event.returnValue = dpa.datosIdentificacion.onKeyyPress(event, campo);
        return  event.returnValue;
};


function identificacionOnChange(event, campo){
	event.returnValue = dpa.datosIdentificacion.onChange(event, campo);
	return event.returnValue; 
};


function OnKeyUp(event, campo){
	var res = true;

	var tecla = ('which' in event) ? event.which : event.keyCode;

	if(event.ctrlKey){
		res = false;
		return res;
	}
	else{
		switch(tecla){
		case BACKSPACE:
		case TAB:
		case IZQ:
		case DER:
		case SUPR:
		case ENTER:
		case INICIO:
			res = true;
			break;
		default:
			this.dpa.OnKeyUp(event, campo);
			break;	
		}
		res = true;
		
		if (this.dpa.sinRFC
				&& (campo==="textfieldCURP"
						|| campo==="textfieldPaterno"
						|| campo==="textfieldMaterno"
						|| campo==="textfieldNombre"
						|| campo==="textfieldRazonSocial")) {
			this.dpa.OnKeyUpSinRFCNombre(event, campo);
		}
		
		this.dpa.OnKeyUpCamposRFC(event, campo);

		if(campo==="textfieldClaveReferenciaDPA"){
			this.dpa.OnKeyUpClaveRefDPA(event, campo);
			
		};

		this.dpa.OnKeyUpImportesDPA(event, campo);
		
		this.dpa.ponerOpcionPago(campo);

	}
	
	event.returnValue = res;
	return res;

};

function confirmarDatos(){
	var res = false;
	try{
		validarConfirmarDatos();
		if(confirm("Validaci\u00f3n de datos\n\u00bfLos datos capturados son correctos\u003f")){
			datosConfirmados = true;
			inhabilitarTodo();
			getXMLValue();
			appEnviaDatos();
			appEnviaImpresion();
			appEnviaCadOriginal();
			res = true;
		}
	}catch(excepcion){
		var msgerror = excepcion.toString();
		if(msgerror.length > 0)
			alert (msgerror);
	}
	return res;
};

function getXMLValue(){
	var res = '*';
	var resDPA = '';
	
	if(datosConfirmados){
		for(var ind=0;ind<pagos.length;ind++){
			dpa = pagos[ind];
			resDPA = resDPA + dpa.getXMLDPA() + dpa.getXMLIVA();
		}
		res = encabezadoXML() + resDPA +  "</DERECHOS>";
	}
	
	return res;
};

function encabezadoXML(){
	
	dpa = pagos[0];
	dpa.datosIdentificacion.rfc.recuperarDeDestino();
	dpa.datosIdentificacion.curp.recuperarDeDestino();
	dpa.datosIdentificacion.paterno.recuperarDeDestino();
	dpa.datosIdentificacion.materno.recuperarDeDestino();
	dpa.datosIdentificacion.nombre.recuperarDeDestino();
	dpa.datosIdentificacion.nombreTarjetaTrib.recuperarDeDestino();
	dpa.datosIdentificacion.razon.recuperarDeDestino();
	
	var stringValue = "<?xml version=\"1.0\" ?>";
	stringValue = stringValue + "<DERECHOS><IDENTIFICACION>";

	if(dpa.datosIdentificacion.rfc.valor.length>0)
		stringValue = stringValue + dpa.datosIdentificacion.rfc.valorXML;
	if(dpa.datosIdentificacion.curp.valor.length>0)
		stringValue = stringValue + dpa.datosIdentificacion.curp.valorXML;
	if(dpa.datosIdentificacion.paterno.valor.length>0)
		stringValue = stringValue + dpa.datosIdentificacion.paterno.valorXML;
	if(dpa.datosIdentificacion.materno.valor.length>0)
		stringValue = stringValue + dpa.datosIdentificacion.materno.valorXML;
	if(dpa.datosIdentificacion.nombre.valor.length>0)
		stringValue = stringValue + dpa.datosIdentificacion.nombre.valorXML;
	if(dpa.datosIdentificacion.nombreTarjetaTrib.valor.length>0)
		stringValue = stringValue + dpa.datosIdentificacion.nombreTarjetaTrib.valorXML;
	if(dpa.datosIdentificacion.razon.valor.length>0)
		stringValue = stringValue + dpa.datosIdentificacion.razon.valorXML;
	
	stringValue = stringValue + totalEfectPagado.valorXML;
	stringValue = stringValue + dpa.datosIdentificacion.dependencia.valorXML;

	stringValue = stringValue + "</IDENTIFICACION>";
	// FIN ENCABEZADO XML 
	
	return stringValue;
}

function xml_to_string(xml_node)
{
	var res;
	
    if (xml_node.xml)
        res =  xml_node.xml;
    else if (XMLSerializer)
    {
        var xml_serializer = new XMLSerializer();
        res =  xml_serializer.serializeToString(xml_node);
    }
    else
    {
        res = "ERROR: Extremely old browser" + xml_node;
    }
    
    return res;
}

function appEnviaDatos(){
	var res = '*';
	var resDPA = '';
	
	if(datosConfirmados){
		for(var ind=0;ind<pagos.length;ind++){
			dpa = pagos[ind];
			resDPA = resDPA + dpa.appEnviaDatosDPA() + dpa.appEnviaDatosIVA();
		}
		res = encabezadoEnviaDatos(pagos[0]) + resDPA  + "EOF|";

	}
	
	return res;
};

function encabezadoEnviaDatos(dpa){
	var stringValue = "";
	if (!dpa.sinRFC) {
		stringValue = stringValue + dpa.datosIdentificacion.rfc.claveInformatica + "=" + dpa.datosIdentificacion.rfc.valor
				+ "|";
	}

	if (dpa.datosIdentificacion.curp.valor.length > 0) {
		stringValue = stringValue + dpa.datosIdentificacion.curp.claveInformatica + "="
				+ dpa.datosIdentificacion.curp.valor + "|";
	}

	if (document.getElementById("textfieldNombreTarjetaTributaria").style.display === 'none') {
		if ((document.getElementById("textfieldRazonSocial").style.display!=='none')
				&& (dpa.datosIdentificacion.razon.valor.length > 0)) {
			stringValue = stringValue + dpa.datosIdentificacion.razon.claveInformatica + "="
					+ dpa.datosIdentificacion.razon.valor + "|";
		} else {
			stringValue = stringValue + dpa.datosIdentificacion.paterno.claveInformatica + "="
					+ dpa.datosIdentificacion.paterno.valor + "|";
			if (dpa.datosIdentificacion.materno.valor.length > 0) {
				stringValue = stringValue + dpa.datosIdentificacion.materno.claveInformatica + "="
						+ dpa.datosIdentificacion.materno.valor + "|";
			}
			stringValue = stringValue + dpa.datosIdentificacion.nombre.claveInformatica + "="
					+ dpa.datosIdentificacion.nombre.valor + "|";
		}
	} else if ((dpa.tarjetaTributariaOK)
			&& (document.getElementById("textfieldNombreTarjetaTributaria").style.display !== 'none')) {
		stringValue = stringValue + dpa.datosIdentificacion.nombreTarjetaTrib.claveInformatica
				+ "=" + dpa.datosIdentificacion.nombreTarjetaTrib.valor + "|";
	}

	stringValue = stringValue + totalEfectPagado.claveInformatica
			+ "=" + totalEfectPagado.valor + "|";
	stringValue = stringValue + dpa.datosIdentificacion.dependencia.claveInformatica + "="
			+ dpa.datosIdentificacion.dependencia.valor + "|";

	return stringValue;
};

function appEnviaImpresion(){
	var res = '*';
	var resDPA = '';
	
	if(datosConfirmados){
		for(var ind=0;ind<pagos.length;ind++){
			dpa = pagos[ind];
			resDPA = resDPA + dpa.appEnviaImpresionDPA() + dpa.appEnviaImpresionIVA();
		}
		res = encabezadoEnviaImpresion(pagos[0]) + resDPA  + "EOF|";
	}
	
	return res;
};

function encabezadoEnviaImpresion(dpa){
	var stringValue = "";
	if (!dpa.sinRFC) {
		stringValue = stringValue + dpa.datosIdentificacion.rfc.descripcion + ":" + dpa.datosIdentificacion.rfc.valor
				+ "|";
	}

	if (dpa.datosIdentificacion.curp.valor.length > 0) {
		stringValue = stringValue + dpa.datosIdentificacion.curp.descripcion + ":"
				+ dpa.datosIdentificacion.curp.valor + "|";
	}

	if (document.getElementById("textfieldNombreTarjetaTributaria").style.display === 'none') {
		if ((document.getElementById("textfieldRazonSocial").style.display!=='none')
				&& (dpa.datosIdentificacion.razon.valor.length > 0)) {
			stringValue = stringValue + dpa.datosIdentificacion.razon.descripcion + ":"
					+ encodeURI(dpa.datosIdentificacion.razon.valor) + "|";
		} else {
			stringValue = stringValue + dpa.datosIdentificacion.paterno.descripcion + ":"
					+ dpa.datosIdentificacion.paterno.valor + "|";
			if (dpa.datosIdentificacion.materno.valor.length > 0) {
				stringValue = stringValue + dpa.datosIdentificacion.materno.descripcion + ":"
						+ dpa.datosIdentificacion.materno.valor + "|";
			}
			stringValue = stringValue + dpa.datosIdentificacion.nombre.descripcion + ":"
					+ dpa.datosIdentificacion.nombre.valor + "|";
		}
	} else if ((dpa.tarjetaTributariaOK)
			&& (document.getElementById("textfieldNombreTarjetaTributaria").style.display !== 'none')) {
		stringValue = stringValue + dpa.datosIdentificacion.nombreTarjetaTrib.descripcion + ":"
				+ dpa.datosIdentificacion.nombreTarjetaTrib.valor + "|";
	}

	stringValue = stringValue + totalEfectPagado.descripcion + ":"
			+ totalEfectPagado.valorConMascara + "|";
	stringValue = stringValue + dpa.datosIdentificacion.dependencia.descripcion + ":"
			+ dpa.datosIdentificacion.dependencia.valorParaImpresion() + "|";

	return stringValue;
	
};

function appEnviaCadOriginal(){
	var res = '*';
	var resDPA = '';
	var resIVA = '';
	
	if(datosConfirmados){
		for(var ind=0;ind<pagos.length;ind++){
			dpa = pagos[ind];
			resIVA = resIVA + dpa.appCadenaOriginalIVA();
			resDPA = resDPA + dpa.appCadenaOriginalDPA();
		}
		res = encabezadoCadenaOriginal() + resIVA + resDPA  + "30003=|30004=|" + "|";
	}
	
	return res;
	
};

function encabezadoCadenaOriginal(){
	var stringValue = "||";
	var dpa = pagos[0];
	
	if (!dpa.sinRFC) {
		stringValue = stringValue + dpa.datosIdentificacion.rfc.claveInformatica + "=" + dpa.datosIdentificacion.rfc.valor
				+ "|";
	} else if (document.getElementById("textfieldNombreTarjetaTributaria").style.display === 'none') {
		if ((document.getElementById("textfieldRazonSocial").style.display!=='none')
				&& (dpa.datosIdentificacion.razon.valor.length > 0)) {
			stringValue = stringValue
					+ dpa.datosIdentificacion.razon.claveInformatica
					+ "="
					+ dpa.datosIdentificacion.razon.sinCaracter(' ') + "|";
		} else {
			stringValue = stringValue
					+ dpa.datosIdentificacion.paterno.claveInformatica
					+ "="
					+ dpa.datosIdentificacion.paterno.sinCaracter(' ') + "|";
			if (dpa.datosIdentificacion.materno.valor.length > 0) {
				stringValue = stringValue
						+ dpa.datosIdentificacion.materno.claveInformatica
						+ "="
						+ dpa.datosIdentificacion.materno.sinCaracter(' ') + "|";
			}
			stringValue = stringValue
					+ dpa.datosIdentificacion.nombre.claveInformatica
					+ "="
					+ dpa.datosIdentificacion.nombre.sinCaracter(' ') + "|";
		}
	} else if ((dpa.tarjetaTributariaOK)
			&& (document.getElementById("textfieldNombreTarjetaTributaria").style.display !== 'none')) {
		stringValue = stringValue
				+ dpa.datosIdentificacion.nombreTarjetaTrib.claveInformatica
				+ "="
				+ dpa.datosIdentificacion.nombreTarjetaTrib.sinCaracter(' ')
				+ "|";
	}

	stringValue = stringValue + totalEfectPagado.claveInformatica
			+ "=" + totalEfectPagado.valor + "|";
	stringValue = stringValue + "20001=|20002=|40002=|40003=|40008=|";


	return stringValue;
	
};

function inhabilitarTodo(){

	document.getElementById("textfieldCURP").disabled = true;
	document.getElementById("textfieldCURP").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldNombreTarjetaTributaria").disabled = true;
	document.getElementById("textfieldNombreTarjetaTributaria").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldPaterno").disabled = true;
	document.getElementById("textfieldPaterno").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldMaterno").disabled = true;
	document.getElementById("textfieldMaterno").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldNombre").disabled = true;
	document.getElementById("textfieldNombre").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldRazonSocial").disabled = true;
	document.getElementById("textfieldRazonSocial").style.backgroundColor=ColorDisabled;
		
	document.getElementById("comboboxDependencia").disabled = true;
	document.getElementById("comboboxDependencia").style.backgroundColor=ColorDisabled;
	document.getElementById("checkboxNoAplicaPeriodo").disabled = true;
	document.getElementById("checkboxNoAplicaPeriodo").style.backgroundColor=ColorDisabled;
	document.getElementById("radiobuttonMensual").disabled = true;
	document.getElementById("radiobuttonMensual").style.backgroundColor=ColorDisabled;
	document.getElementById("radiobuttonBimestral").disabled = true;
	document.getElementById("radiobuttonBimestral").style.backgroundColor=ColorDisabled;
	document.getElementById("radiobuttonTrimestral").disabled = true;
	document.getElementById("radiobuttonTrimestral").style.backgroundColor=ColorDisabled;
	document.getElementById("radiobuttonCuatrimestral").disabled = true;
	document.getElementById("radiobuttonCuatrimestral").style.backgroundColor=ColorDisabled;
	document.getElementById("radiobuttonSemestral").disabled = true;
	document.getElementById("radiobuttonSemestral").style.backgroundColor=ColorDisabled;
	document.getElementById("radiobuttonDelEjercicio").disabled = true;
	document.getElementById("radiobuttonDelEjercicio").style.backgroundColor=ColorDisabled;
	document.getElementById("comboboxEjercicio").disabled = true;
	document.getElementById("comboboxEjercicio").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldClaveReferenciaDPA").disabled = true;
	document.getElementById("textfieldClaveReferenciaDPA").style.backgroundColor=ColorDisabled;
	document.getElementById("comboboxPeriodo").disabled = true;
	document.getElementById("comboboxPeriodo").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldCadenaDependencia").disabled = true;
	document.getElementById("textfieldCadenaDependencia").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldSaldoFavor").disabled = true;
	document.getElementById("textfieldSaldoFavor").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldACargo").disabled = true;
	document.getElementById("textfieldACargo").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldParteActualizada").disabled = true;
	document.getElementById("textfieldParteActualizada").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldRecargos").disabled = true;
	document.getElementById("textfieldRecargos").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldMultaCorrecion").disabled = true;
	document.getElementById("textfieldMultaCorrecion").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldCompensaciones").disabled = true;
	document.getElementById("textfieldCompensaciones").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldCantidadPagar").disabled = true;
	document.getElementById("textfieldCantidadPagar").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldTotalEfectivamentePagado").disabled = true;
	document.getElementById("textfieldTotalEfectivamentePagado").style.backgroundColor=ColorDisabled;

	document.getElementById("textfieldIvaACargo").disabled = true;
	document.getElementById("textfieldIvaACargo").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldIvaParteActualizada").disabled = true;
	document.getElementById("textfieldIvaParteActualizada").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldIvaRecargos").disabled = true;
	document.getElementById("textfieldIvaRecargos").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldIvaMultaCorrecion").disabled = true;
	document.getElementById("textfieldIvaMultaCorrecion").style.backgroundColor=ColorDisabled;
	document.getElementById("textfieldTotalIva").disabled = true;
	document.getElementById("textfieldTotalIva").style.backgroundColor=ColorDisabled;
	document.getElementById("listDetalle").disabled = true;
	document.getElementById("listDetalle").style.backgroundColor=ColorDisabled;

	document.getElementById("buttonAgregarConcepto").disabled = true;
	document.getElementById("buttonBorrarConcepto").disabled = true;
	document.getElementById("buttonConfirmarDatos").disabled = true;
	document.getElementById("buttonBorrarTodo").disabled = false;
};

function validarLimpioCampo(campo){

	dpa.validarLimpioCampo(campo);

};

function validarConfirmarDatos(){
	
	if (pagos.length == 0) {
		throw "Debe capturar al menos un concepto.";
	}

	var dpaPrimero = pagos[0];

	if (!dpaPrimero.sinRFC) {
		dpaPrimero.datosIdentificacion.rfc.recuperarDeDestino();
	}

	totalEfectPagado.validar();
	
	dpaPrimero.datosIdentificacion.curp.recuperarDeDestino();
	if(dpaPrimero.datosIdentificacion.curp.habilitado() ){
		dpaPrimero.datosIdentificacion.razon.obligatorio = false;
		
		dpaPrimero.datosIdentificacion.materno.recuperarDeDestino();
		dpaPrimero.datosIdentificacion.materno.obligarorio = false;
		dpaPrimero.datosIdentificacion.materno.validar();
		if(dpaPrimero.datosIdentificacion.materno.errorValidacion)
			throw "";
		dpaPrimero.datosIdentificacion.paterno.recuperarDeDestino();
		dpaPrimero.datosIdentificacion.paterno.obligatorio = true;
		dpaPrimero.datosIdentificacion.paterno.validar();
		if(!dpaPrimero.datosIdentificacion.paterno.errorValidacion){
			dpaPrimero.datosIdentificacion.nombre.recuperarDeDestino();
			dpaPrimero.datosIdentificacion.nombre.obligatorio = true;
			dpaPrimero.datosIdentificacion.nombre.validar();
			if(dpaPrimero.datosIdentificacion.nombre.errorValidacion)
				throw "";
		}else
			throw "";
	}else{
		dpaPrimero.datosIdentificacion.paterno.obligatorio = false;
		dpaPrimero.datosIdentificacion.nombre.obligatorio = false;
		dpaPrimero.datosIdentificacion.razon.recuperarDeDestino();
		dpaPrimero.datosIdentificacion.razon.obligatorio = true;
		dpaPrimero.datosIdentificacion.razon.validar();
	}
	
	if (document.getElementById("textfieldNombreTarjetaTributaria").style.display === 'none') {
		if ((dpaPrimero.sinRFC) && (dpaPrimero.datosIdentificacion.curp.valor.length == 0)
				&& (dpaPrimero.datosIdentificacion.paterno.valor.length == 0)
				&& (dpaPrimero.datosIdentificacion.materno.valor.length == 0)
				&& (dpaPrimero.datosIdentificacion.nombre.valor.length == 0)
				&& (dpaPrimero.datosIdentificacion.razon.valor.length == 0)) {
			throw "Hacen falta datos de identificaci\u00f3n.";
		}else{
			if(dpaPrimero.datosIdentificacion.curp.valor.length > 0){
				dpaPrimero.datosIdentificacion.curp.validar();
				if(dpaPrimero.datosIdentificacion.curp.errorValidacion)
					throw '';
			}
			
			if(dpaPrimero.datosIdentificacion.paterno.valor.length > 0){
				dpaPrimero.datosIdentificacion.paterno.validar();
				if(dpaPrimero.datosIdentificacion.paterno.errorValidacion)
					throw '';
			}

			if(dpaPrimero.datosIdentificacion.nombre.valor.length > 0){
				dpaPrimero.datosIdentificacion.nombre.validar();
				if(dpaPrimero.datosIdentificacion.nombre.errorValidacion)
					throw '';
			}

			if(dpaPrimero.datosIdentificacion.razon.valor.length > 0){
				dpaPrimero.datosIdentificacion.razon.validar();
				if(dpaPrimero.datosIdentificacion.razon.errorValidacion)
					throw '';
			}
		}
	}
	dpaPrimero.datosIdentificacion.nombreTarjetaTrib.recuperarDeDestino();

	var DelEjercicio = dpaPrimero.detalleConcepto.noAplicaPeriodo.tiposPeriodo[5];
	
	if ((DelEjercicio.valor==='checked')
			&& (dpaPrimero.detalleConcepto.saldoFavorDPA.habilitado())) {
		if (dpaPrimero.detalleConcepto.saldoFavorDPA.valor.length > 0) {
			if (dpaPrimero.detalleConcepto.saldoFavorDPA.valorNumerico() <= 0) {
				throw "Saldo a favor\nDebe ser mayor a cero.";
			}
		} else
			throw "Saldo a favor\nDebe ser mayor a cero.";

	}

	dpaPrimero.detalleConcepto.compensacionesDPA.recuperarDeDestino();
	if ((dpaPrimero.detalleConcepto.compensacionesDPA.valor.length > 0)
			&& ((dpaPrimero.detalleConcepto.compensacionesDPA.valorNumerico() <= 0) 
					|| (dpaPrimero.detalleConcepto.compensacionesDPA.valorNumerico() > this.dpa.totalContribuciones))) {
		throw "Compensaciones\nDebe ser mayor a cero y menor o igual a total de contribuciones.";
	}
};

function borrarConcepto(){
	var dpaBorrar = null;
	var ind=0;
	if(pagos.length>0){
		var listDetalle = document.getElementById("listDetalle");
		if(listDetalle!==null){
			if(listDetalle.selectedIndex >= 0){
				for(ind=0;ind<pagos.length;ind++){
					dpaBorrar = pagos[ind];
					if(listDetalle.selectedIndex>=dpaBorrar.lineaInicial && listDetalle.selectedIndex<=dpaBorrar.lineaFinal){
						for(var linea=dpaBorrar.lineaInicial ; linea <=dpaBorrar.lineaFinal;linea++ ){
							listDetalle.remove(dpaBorrar.lineaInicial);
						}
						pagos.splice(ind, 1);
						break;
					}
				}
				indPAGOS = pagos.length;
				if(dpaBorrar!==null){
					dpaBorrar.borrarConcepto();
					dpaBorrar.reiniciarDetalle();
					var total = totalEfectPagado.valorNumerico() - (dpaBorrar.cantidadPagar + dpaBorrar.cantidadPagarIVA);
					totalEfectPagado.ponerCampo(total);				
				}
				while (listDetalle.options.length > 0) {                
					listDetalle.remove(0);
			    }
				for(ind=0;ind<pagos.length;ind++){
					dpaBorrar = pagos[ind];
					dpaBorrar.lineaInicial = listDetalle.length;
					dpaBorrar.addListDetalleValues(claveInfDPA, dpaBorrar.valoresDPA);
					if(dpaBorrar.conValoresIVA())
						dpaBorrar.addListDetalleValues(claveInfIVA, dpaBorrar.valoresIVA);
					dpaBorrar.lineaFinal = listDetalle.length - 1;
				}
				if(pagos.length==0){
					document.getElementById("buttonBorrarConcepto").disabled = true;
				}
			}
		}
	}
};

function borrarTodo(preguntar){
	var listDetalle = document.getElementById("listDetalle");
	
	if(preguntar=='1'){
		if(!confirm("Borrar datos capturados\n\u00bfEst\u00e1 seguro de borrar todos los datos\u003f")){
			return true;
		}
	}
	while (pagos.length > 0) {
		listDetalle.selectedIndex = 0;
		borrarConcepto();
    }
	reiniciar();
};

function getAllValues(){
	document.idForm.datosXML.value = getXMLValue();
	document.idForm.datos.value = appEnviaDatos();
	document.idForm.datosImpresion.value = appEnviaImpresion();
	document.idForm.cadenaOriginal.value = appEnviaCadOriginal();

	if(document.idForm.datosXML.value=="*")
	{
		alert("Debe confirmar previamente sus datos");
		return false;
	}

	else
	{
		open("PrintValues.html","PrintValues","fullscreen=no,resizable=yes,width=600,height=300,scrollbars=yes,toolbar=no,status=no,menubar=no,top=50,left=50");
		borrarTodo('0');
		reiniciar();
		return true;
	}
};

function log(msg) {
    setTimeout(function() {
        throw new Error(msg);
    }, 0);
};